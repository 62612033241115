<template>
  <v-row justify="center">
    <v-col cols="5">
      <v-toolbar class="transparent elevation-0">
        <v-btn icon color="secondary" @click="goBack()">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Transfer</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <!-- <icon icon="fe:list-bullet" width="24" height="24" /> -->
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-card class="elevation-0 transparent">
          <v-card-text>
            <v-form ref="transferForm" lazy-loading>
              <label class="secondary--text">From</label>
              <v-row>
                <v-col>
                  <v-select
                    v-model="fromWallet"
                    dense
                    filled
                    :items="wallets"
                    required
                    disabled
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <v-btn icon @click="switchCoins">
                    <icon
                      icon="gg:arrows-exchange-alt-v"
                      width="24"
                      height="24"
                    ></icon>
                  </v-btn>
                </v-col>
              </v-row>
              <label class="secondary--text">To</label>
              <v-row class="d-flex">
                <v-col>
                  <v-select
                    v-model="toWallet"
                    dense
                    filled
                    :items="wallets"
                    required
                    disabled
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <label class="secondary--text mt-5">Coin</label>
              <v-row class="d-flex">
                <v-col>
                  <v-select
                    v-model="selectedCoin"
                    dense
                    filled
                    :items="coins"
                    required
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <div class="d-flex justify-content-between mt-5">
                <label class="secondary--text">Amount</label>
                <label
                  :class="
                    balance <= 0
                      ? 'error--text font-weight-bold'
                      : 'success--text font-weight-bold'
                  "
                  >Available: {{ balance | commaFormat }}</label
                >
              </div>
              <div class="d-flex" style="flex: 12">
                <div class="pt-0 mt-0" style="flex: 10">
                  <v-text-field
                    v-model="amount"
                    dense
                    filled
                    required
                    :rules="amountRules()"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                  ></v-text-field>
                </div>
                <div class="pt-0 mt-0" style="flex: 2">
                  <v-btn
                    color="secondary"
                    style="height: 41px"
                    @click="maxAmount"
                    ><span class="font-weight-bold">Max</span>
                  </v-btn>
                </div>
              </div>
              <v-row class="mt-8">
                <v-col cols="12" class="text-center">
                  <v-btn
                    block
                    large
                    color="primary"
                    @click="confirm(), (loader = 'loading')"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Transfer
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
      <v-dialog v-model="confirmDialog" persistent>
        <v-card width="400px">
          <v-card-title>
            <v-toolbar class="elevation-0 transparent">
              <v-spacer></v-spacer>
              <v-toolbar-title>Confirmation</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-form ref="confirmationForm" lazy-loading>
              <v-row>
                <v-col cols="12">
                  <label>Transaction Password</label>
                  <v-text-field
                    v-model="password"
                    :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="visible = !visible"
                    :rules="passwordRules()"
                    :type="visible ? 'text' : 'password'"
                    :error-messages="errorMessage.password"
                    dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" large text @click="confirmDialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="px-4"
              large
              @click="transfer(), (loader = 'loading')"
              :loading="loading"
              :disabled="loading"
            >
              Confirm
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    visible: false,
    wallets: ["SPOT", "FUTURE", "FUNDING"],
    fromWallet: "SPOT",
    toWallet: "FUTURE",
    selectedCoin: "BTC",
    amount: 0,
    password: "",
    errorMessage: {
      password: "",
    },
    confirmDialog: false,
  }),
  computed: {
    transferType() {
      return this.$store.state.transferType;
    },
    coins() {
      //Extract coins from store get only the name
      let coins = this.$store.getters.marketCoins.map((coin) => coin.name);
      //Remove duplicates
      coins = coins.filter((coin, index) => coins.indexOf(coin) === index);
      //Add USDT
      coins.push("USDT");
      return coins;
    },
    balanceInFuture() {
      let wallets = this.$store.getters.futureWallet.wallet;
      return wallets.find((item) => item.name === this.selectedCoin).usdt_value;
    },
    balanceInSpot() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === "USDT").usdt_value;
    },
    balance() {
      return this.fromWallet === "SPOT"
        ? this.balanceInSpot
        : this.balanceInFuture;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    fetchWallets() {
      this.$store.dispatch("setFutureWallet");
      this.$store.dispatch("setWallet");
    },
    switchCoins() {
      let temp = this.fromWallet;
      this.fromWallet = this.toWallet;
      this.toWallet = temp;
    },
    amountRules() {
      return this.fromWallet === "SPOT"
        ? [
            (v) => !!v || "Amount is required",
            (v) =>
              v <= this.balanceInSpot || "Insufficient balance in spot wallet",
          ]
        : [
            (v) => !!v || "Amount is required",
            (v) =>
              v <= this.balanceInFuture ||
              "Insufficient balance in future wallet",
          ];
    },
    passwordRules() {
      return [(v) => !!v || "Password is required"];
    },
    confirm() {
      if (this.$refs.transferForm.validate()) this.confirmDialog = true;
    },
    transfer() {
      if (this.$refs.confirmationForm.validate()) {
        this.loading = true;
        this.$axios
          .post("https://api.axtoken.net/api/v1/futures/transfer", {
            coin_name: this.selectedCoin,
            state: "PENDING",
            draw_type: this.fromWallet == "SPOT" ? "DRAW_IN" : "DRAW_OUT",
            transfer_amount: this.amount,
            transaction_password: this.password,
          })
          .then((response) => {
            this.loading = false;
            if (response.data.message == "success") {
              this.$swal({
                title: "Success",
                text: "Transfer successfully",
                icon: "success",
                timer: 3000,
                buttons: false,
              });
              this.$store.dispatch("setWallet");
              this.$store.dispatch("setFutureWallet");
              this.$router.push("/wallet");
            } else if (response.data.message === "wrong_transaction_password") {
              this.$swal({
                title: "Error",
                text: "Wrong transaction password",
                icon: "error",
                timer: 3000,
                buttons: false,
              });
              this.errorMessage.password = "Wrong transaction password";
            } else {
              this.$swal({
                title: "Error",
                text: "Something went wrong",
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$swal({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 3000,
              buttons: false,
            });
          });
        this.confirmDialog = false;
        this.fetchWallets();
      }
    },
    maxAmount() {
      this.amount = this.balance;
    },
  },
  mounted() {
    this.fetchWallets();
    this.toWallet = this.transferType;
  },
};
</script>
<style>
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
