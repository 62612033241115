<template>
    <v-card class="overview-portfolio-card">
        <v-card-text>
            <v-row justify="space-between">
                <v-col>
                    <h4 class="font-weight-bold mb-0 white--text">Portfolio</h4>
                </v-col>
            </v-row>
            <v-row>
                <v-list v-if="!loading">
                    <v-list-item v-for="(coin, index) in coins" :key="index">
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold">{{
                                coin.name
                            }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-title
                                class="subtitle-2 font-weight-bold"
                                >{{
                                    coin.value | commaFormat
                                }}
                                USDT</v-list-item-title
                            >
                            <span class="sub-caption"
                                >={{ coin.value | commaFormat }} USDT</span
                            >
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-skeleton-loader
                    v-else
                    type="list-item-three-line"
                    class="mx-auto"
                    :loading="loading"
                ></v-skeleton-loader>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        coins: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style>
.overview-portfolio-card {
    margin-top: 20px !important;
    width: 100%;
    margin: 0;
    padding: 0;
}
</style>
