<template>
  <div>
    <v-card v-if="!loading" class="balance-card">
      <v-card-text>
        <v-row>
          <v-col>
            <span class="caption">Total Balance(USDT)</span>
            <h2 class="font-weight-bold mb-0 greenPrimary--text">
              {{ balance | commaFormat }}
            </h2>
            <span class="sub-caption">={{ balance | commaFormat }} USDT</span>
          </v-col>
        </v-row>
        <v-row justify="space-between" class="">
          <v-col class="text-center px-2">
            <v-btn
              to="/wallet/earn"
              elevation="0"
              fab
              mix-width="60"
              min-heigth="60"
            >
              <icon
                icon="ph:piggy-bank-duotone"
                color="#fcd535"
                width="35"
                height="35"
              />
            </v-btn>
            <p id="btn-caption">Earn</p>
          </v-col>
          <v-col class="text-center px-2">
            <v-btn
              to="/wallet/auto_invest"
              elevation="0"
              fab
              mix-width="60"
              min-heigth="60"
              id="custom-btn"
            >
              <icon
                icon="ph:arrows-counter-clockwise-duotone"
                color="#fcd535"
                width="35"
                height="35"
              />
            </v-btn>
            <p id="btn-caption">Auto Invest</p>
          </v-col>
          <v-col class="text-center px-2">
            <v-btn
              to="/wallet/analysis"
              elevation="0"
              fab
              mix-width="60"
              min-heigth="60"
              id="custom-btn"
            >
              <icon
                icon="ph:brain-duotone"
                color="#fcd535"
                width="35"
                height="35"
              />
            </v-btn>
            <p id="btn-caption">Analysis</p>
          </v-col>
          <v-col class="text-center px-2">
            <v-btn
              to="/wallet/transactions"
              elevation="0"
              fab
              mix-width="60"
              min-heigth="60"
              id="custom-btn"
            >
              <icon
                icon="ph:receipt-duotone"
                color="#fcd535"
                width="35"
                height="35"
              />
            </v-btn>
            <p id="btn-caption">Transactions</p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-card class="my-2" v-for="(stake, index) in stakes" :key="index">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-avatar size="25">
                      <v-img :src="stake.image"></v-img>
                    </v-avatar>
                    <span class="h5 white--text ml-2">{{ stake.name }}</span>
                    <p class="body-2 mb-0 grey--text">
                      APR
                      <strong class="greenPrimary--text"
                        >{{ stake.apr }}%</strong
                      >
                    </p>
                    <p class="body-2 mb-0 grey--text">
                      {{
                        (stake.type = "FLEXI_LOCK"
                          ? "FLEXI LOCK"
                          : "STAKE LOCK")
                      }}
                    </p>
                  </v-col>
                  <v-col class="text-end">
                    <p class="h5 mb-0 white--text">
                      <strong class="white--text">{{ stake.balance }}</strong>
                    </p>
                    <p class="caption mb-0 grey--text">Accumulative Interest</p>
                    <p class="caption mb-0">
                      <strong class="greenPrimary--text">{{
                        stake.price
                      }}</strong>
                    </p>
                    <p class="caption mb-0 grey--text">
                      {{ stake.date_start }} -
                      {{ stake.date_end }}
                    </p>
                    <v-btn
                      class="mt-2"
                      small
                      rounded
                      color="secondary"
                      @click="confirmDialog(stake)"
                    >
                      unlock
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-container v-else full-screen-container>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <v-progress-circular
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      stakes: [],
      balance: 0,
      initialPayment: 0,
    };
  },
  mounted() {
    this.fetchStaking();
  },
  methods: {
    fetchStaking() {
      this.loading = true;
      this.$axios
        .get("https://api.axtoken.net/api/v1/stake")
        .then((response) => {
          this.stakes = response.data.earnStaking;
          this.balance = response.data.usdtBalance;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    confirmDialog(stake) {
      this.initialPayment = stake.balance * 0.3 * stake.price;
      this.$swal({
        title: "Are you sure?",
        text: `Your account must have initial payment ${this.initialPayment} in order to unlock the staked coins and its profit. Do you wish to continue?`,
        showCancelButton: true,
        confirmButtonText: "Yes, unlock it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.unlockStake(stake.id);
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          this.$swal({
            title: "Cancelled",
            text: "Your stake is safe!",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
    },
    unlockStake(id) {
      this.$axios
        .get("https://api.axtoken.net/api/v1/unlock", {
          params: { earn_staking_id: id },
        })
        .then((response) => {
          if (response.data.message == "success") {
            this.$swal({
              title: "Success",
              text: "Stake unlocked",
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetchStaking();
          } else {
            this.$swal({
              title: "Error",
              text: "Sorry you have insufficient Balance",
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.balance-card {
  margin-top: 20px !important;
  border-radius: 30px 30px 0 0 !important;
  width: 100%;
  margin: 0;
  padding: 0;
}

.sub-caption {
  font-size: 10px;
}

.full-screen-container {
  height: 90vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
