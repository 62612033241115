<template>
  <v-row justify="center">
    <v-col cols="5">
      <v-toolbar class="transparent elevation-0">
        <v-btn icon color="secondary" @click="goBack()">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Account Info</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar>
      <v-container>
        <div class="d-flex justify-content-center">
          <v-avatar class="mx-auto" size="100">
            <icon
              icon="solar:user-circle-bold-duotone"
              width="16"
              height="16"
            />
            <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img> -->
          </v-avatar>
        </div>
        <v-list class="transparent text-center">
          <v-list-item link>
            <v-list-item-content>
              <h3>{{ user.user_unique_id }}</h3>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list class="transparent">
          <v-list-item class="transparent my-2" to="/account/verify">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                Verifications
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ client.verified ? "Verified" : "Unverified" }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <icon icon="fa:chevron-right" width="16" height="16" />
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="transparent my-2">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                Membership
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ client.membership_level }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <icon icon="fa:chevron-right" width="16" height="16" />
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="transparent my-2">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                UUID
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ user.user_unique_id }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="copyUserId()">
                <icon icon="fa:copy" width="16" height="16" />
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="transparent my-2">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                Registration Info
              </v-list-item-title>
              <v-list-item-subtitle> {{ user.email }} </v-list-item-subtitle>
            </v-list-item-content>
            <!-- <v-list-item-action>
            <v-btn icon>
              <icon icon="fa:eye" width="16" height="16" />
            </v-btn>
          </v-list-item-action> -->
          </v-list-item>
        </v-list>
      </v-container>
      <v-btn
        color="primary"
        fixed
        bottom
        rounded
        large
        block
        @click="logout(), (loader = 'loading')"
        :loading="loading"
        :disabled="loading"
      >
        Logout
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn></v-col
    >
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    items: [
      {
        title: "Verification",
        to: "change-password",
        value: "Verified",
      },
      {
        title: "Regular",
        to: "change-transaction-password",
        value: "Regular",
      },
    ],
  }),
  computed: {
    client() {
      return this.$store.getters.client;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    logout() {
      this.loading = true;
      this.$store.dispatch("logout");
      setTimeout(() => {
        this.loading = false;
        this.$router.push("/signin");
      }, 1000);
    },

    copyUserId() {
      const el = document.createElement("textarea");
      el.value = this.user.user_unique_id;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Copied to clipboard",
        showConfirmButton: false,
        timer: 3000, // milliseconds
      });
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
