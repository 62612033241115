<template>
  <v-row justify="center">
    <v-col cols="5">
      <v-toolbar class="transparent elevation-0">
        <!-- <v-btn icon color="secondary" to="/security">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-toolbar-title class="display-1">Change Password</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar>
      <v-container>
        <v-card class="transparent elevation-0">
          <v-card-text>
            <v-form ref="changePassForm" lazy-loading>
              <label>Old Password</label>
              <v-row align="center">
                <v-col cols="12">
                  <v-text-field
                    v-model="password"
                    :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="visible = !visible"
                    :rules="rules.required"
                    :type="visible ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <label>New Password</label>
              <v-row align="center">
                <v-col cols="12">
                  <v-text-field
                    v-model="newPassword"
                    :append-icon="visible1 ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="visible1 = !visible1"
                    :rules="rules.newPasswordRule"
                    :type="visible1 ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <label>Confirm Password</label>
              <v-row align="center">
                <v-col cols="12">
                  <v-text-field
                    v-model="confirmPassword"
                    :append-icon="visible2 ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="visible2 = !visible2"
                    :rules="rules.confirmPasswordRule"
                    :type="visible2 ? 'text' : 'password'"
                    :error-message="errorMessage.confirmPassword"
                    @keydown.enter="changePassword()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                color="primary"
                class="mt-6"
                rounded
                large
                block
                @click="changePassword(), (loader = 'loading')"
                :loading="loading"
                :disabled="loading"
              >
                Change Password
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    visible: false,
    visible1: false,
    visible2: false,
    password: "",
    newPassword: "",
    confirmPassword: "",
    errorMessage: {
      confirmPassword: "",
    },
    rules: {
      required: [(v) => !!v || "Field is required"],
      newPasswordRule: [
        (v) => !!v || "Field is required",
        (v) => v.length >= 6 || "Password must be at least 6 characters",
      ],
    },
  }),
  methods: {
    changePassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.$swal({
          title: "Error!",
          text: "Password does not match",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        if (this.$refs.changePassForm.validate()) {
          this.loading = true;
          this.$axios
            .get("https://api.axtoken.net/api/v1/update-password", {
              params: {
                oldLoginPassword: this.password,
                password: this.newPassword,
              },
            })
            .then((response) => {
              this.loading = false;
              if (response.data.message === "saved") {
                this.$swal({
                  title: "Success!",
                  text: "Password changed successfully",
                  icon: "success",
                  confirmButtonText: "Ok",
                });
                this.$router.push("/security");
              } else if (response.data.message === "dont_match") {
                this.$swal({
                  title: "Error!",
                  text: "Invalid Old Password",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            });
        }
      }
    },
  },
};
</script>
<style>
/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
