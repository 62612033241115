<template>
  <v-app-bar class="elevation-0 transparent" max-height="64px">
    <v-toolbar-title @click="goHome()" style="cursor: pointer"
      ><v-avatar class="mr-2" size="30"
        ><img src="@/assets/images/ax-logo.png" /></v-avatar
      >AXToken
    </v-toolbar-title>
    <v-spacer />
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          Markets
          <icon icon="heroicons-solid:chevron-down" width="24" height="24"
        /></v-btn>
      </template>
      <v-list>
        <v-list-item to="/markets/overview">
          <v-list-item-icon>
            <icon icon="fe:line-chart" width="24" height="24" />
          </v-list-item-icon>
          <v-list-item-title>Overview</v-list-item-title>
        </v-list-item>
        <v-list-item to="/markets/trading_data">
          <v-list-item-icon>
            <icon icon="ic:round-candlestick-chart" width="24" height="24" />
          </v-list-item-icon>
          <v-list-item-title>Trading Data</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          Trade
          <icon icon="heroicons-solid:chevron-down" width="24" height="24"
        /></v-btn>
      </template>
      <v-list>
        <v-list-item to="/trades/spot/BTC">
          <v-list-item-icon>
            <icon icon="ph:coin" width="24" height="24" />
          </v-list-item-icon>
          <v-list-item-title>Spot</v-list-item-title>
        </v-list-item>
        <v-list-item to="/trades/futures/BTC">
          <v-list-item-icon>
            <icon icon="ion:receipt" width="24" height="24" />
          </v-list-item-icon>
          <v-list-item-title>Future</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          Earn
          <icon icon="heroicons-solid:chevron-down" width="24" height="24"
        /></v-btn>
      </template>
      <v-list>
        <v-list-item to="/wallet/simple_earn">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Simple Earn</v-list-item-title>
        </v-list-item>
        <v-list-item to="/wallet/auto_invest">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Auto-Invest</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer />
    <v-hover v-slot="{ hover }">
      <v-btn
        :color="hover ? 'secondary' : ''"
        class="black--text"
        :text="hover ? false : true"
        :class="hover ? '' : 'white--text'"
        @click="goToSection('download')"
      >
        Download</v-btn
      >
    </v-hover>
    <div v-if="!isAuthenticated">
      <v-btn class="mr-2" text to="/signin"> Login </v-btn>
      <v-btn color="secondary" class="black--text" to="/signup">
        Register
      </v-btn>
    </div>
    <div v-else>
      <v-btn text to="/wallet">
        Wallet
        <!-- <icon
                    icon="heroicons-solid:chevron-down"
                    width="24"
                    height="24"
                /> -->
      </v-btn>
      <v-menu offset-y left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on"
            ><icon
              icon="material-symbols:account-circle"
              width="24"
              height="24"
            />
          </v-btn>
        </template>
        <v-list>
          <v-list-item link to="/profile">
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ user.email }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-chip
                  v-if="client.verified"
                  class="ma-2"
                  color="greenPrimary"
                  outlined
                  small
                >
                  <v-icon left> mdi-check </v-icon>
                  Verified
                </v-chip>
                <v-chip v-else class="ma-2" outlined small> Unverified </v-chip>
                <v-chip
                  v-if="client.membership_level != 'NO_BADGE'"
                  class="ma-2"
                  color="greyPrimary"
                  small
                  label
                >
                  {{ client.membership_level }}
                </v-chip></v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action>
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in accountMenus"
            :key="index"
            :to="item.to"
          >
            <v-list-item-title
              ><icon class="mr-1" :icon="item.icon" width="24" height="24" />
              {{ item.title }}</v-list-item-title
            >
          </v-list-item>
          <v-list-item href="https://direct.lc.chat/15586128/" target="_blank">
            <v-list-item-title
              ><icon
                class="mr-1"
                icon="solar:dialog-bold-duotone"
                width="24"
                height="24"
              />
              Help & Support</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title
              ><icon
                class="mr-1"
                icon="solar:logout-2-bold-duotone"
                width="24"
                height="24"
              />
              Logout</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon
        ><icon icon="heroicons:bell-20-solid" width="24" height="24" />
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    accountMenus: [
      {
        title: "Tasks Center",
        icon: "solar:checklist-bold-duotone",
        to: "/account/tasks",
      },
      {
        title: "Rewards Center",
        icon: "solar:ticket-bold-duotone",
        to: "/account/rewards",
      },
      {
        title: "Pay",
        icon: "solar:hand-money-bold-duotone",
        to: "/account/pay",
      },
      {
        title: "Referral",
        icon: "solar:user-plus-bold-duotone",
        to: "/account/referral",
      },
      {
        title: "Security",
        icon: "solar:shield-minimalistic-bold-duotone",
        to: "/account/security",
      },
      {
        title: "Clear Cache",
        icon: "solar:broom-bold-duotone",
        to: "/account/cache",
      },
    ],
  }),
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    client() {
      return this.$store.getters.client;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    walletClicked(route) {
      this.$store.commit("setWalletView", route);
      this.$router.push("/wallet");
    },
    goToSection(section) {
      //Route to "/" with #download
      if (this.$route.path == "/")
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      else this.$router.push({ path: "/", hash: section });
    },
    logout() {
      this.loading = true;
      this.$store.dispatch("logout");
      setTimeout(() => {
        this.loading = false;
        this.$router.push("/signin");
      }, 1000);
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
