<template>
  <v-row justify="center">
    <v-col cols="8">
      <v-toolbar class="transparent elevation-0">
        <v-btn icon color="secondary" @click="goBack">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Earn</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar>
      <v-row justify="space-between" class="mt-3">
        <v-col class="text-center px-2">
          <v-btn
            to="/wallet/simple-earn"
            elevation="0"
            fab
            mix-width="60"
            min-heigth="60"
          >
            <icon
              icon="ph:coins-duotone"
              color="#fcd535"
              width="35"
              height="35"
            />
          </v-btn>
          <p id="btn-caption">Simple Earn</p>
        </v-col>
        <v-col class="text-center px-2">
          <v-btn
            to="/wallet/auto-invest"
            elevation="0"
            fab
            mix-width="60"
            min-heigth="60"
            id="custom-btn"
          >
            <icon
              icon="ph:arrows-counter-clockwise-duotone"
              color="#fcd535"
              width="35"
              height="35"
            />
          </v-btn>
          <p id="btn-caption">Auto Invest</p>
        </v-col>
        <v-col class="text-center px-2">
          <v-btn
            @click="walletSelected()"
            elevation="0"
            fab
            mix-width="60"
            min-heigth="60"
            id="custom-btn"
          >
            <icon
              icon="ph:wallet-duotone"
              color="#fcd535"
              width="35"
              height="35"
            />
          </v-btn>
          <p id="btn-caption">Earn Wallet</p>
        </v-col>
      </v-row>
      <main-earn-toolbar :search.sync="search" />
      <main-earn-coinlist :search="search" />
    </v-col>
  </v-row>
</template>
<script>
import store from "@/store";
export default {
  name: "earn",
  data: () => ({
    search: "",
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    walletSelected() {
      this.$store.commit("setWalletView", "tab-4");
      this.$router.push("/wallet");
    },
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    const isAuthenticated = store.getters.isAuthenticated;
    if (isAuthenticated) {
      next();
    } else {
      next("/signin");
    }
  },
};
</script>
