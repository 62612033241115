<template>
  <v-row justify="center">
    <v-col cols="8">
      <v-toolbar class="transparent elevation-0">
        <v-btn icon color="secondary" @click="goBack">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Simple Earn</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar>
      <main-earn-toolbar :search.sync="search" />
      <main-earn-coin-list-simple :search="search" />
    </v-col>
  </v-row>
</template>
<script>
import store from "@/store";
export default {
  name: "earn",
  data: () => ({
    search: "",
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    walletSelected() {
      this.$store.commit("setWalletView", "tab-4");
      this.$router.push("/wallet");
    },
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    const isAuthenticated = store.getters.isAuthenticated;
    if (isAuthenticated) {
      next();
    } else {
      next("/signin");
    }
  },
};
</script>
