import "bootstrap/dist/css/bootstrap.min.css";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./plugins";

// Register Components
import "./components";

Vue.config.productionTip = false;

window.Pusher = require("pusher-js");
new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");

import "bootstrap/dist/js/bootstrap.js";
