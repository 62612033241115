<template>
    <v-row align="center" class="ma-0" @click="coinClicked()">
        <v-col cols="4">
            <div class="d-flex justify-content-start align-items-center">
                <v-avatar size="25">
                    <img :src="image" :alt="name" />
                </v-avatar>
                <strong class="ml-3 mr-2">{{ name }}</strong>
                <span class="caption grey--text darken-2">USDT</span>
            </div>
        </v-col>
        <v-col cols="4" class="text-center">
            <p class="font-weight-bold white--text text--lighten-1 mb-0">
                {{ amount }}
            </p>
        </v-col>
        <v-col cols="4" class="text-center">
            <p class="font-weight-bold greenPrimary--text text--lighten-1 mb-0">
                {{ percentWithSign }}%
            </p>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: {
        name: String,
        image: String,
        percent: String,
        amount: String,
    },
    computed: {
        percentColor() {
            //Return the color based on the percent value if positive or negative
            return this.percent >= 0 ? "greenPrimary" : "redPrimary";
        },
        percentWithSign() {
            //Return the percent value with a sign
            return this.percent >= 0 ? "+" + this.percent : this.percent;
        },
    },
    methods: {
        coinClicked() {
            this.$store.state.selectedCoin = this.name;
            this.$router.push(`/trades/spot/${this.name}`);
        },
    },
};
</script>
<style>
.percent-card {
    font-weight: 550;
}
</style>
