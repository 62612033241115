<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="11" xl="8" class="ma-0 pa-0">
        <v-card class="mb-5 py-12 hero-section transparent elevation-0">
          <v-card-text>
            <v-row justify="space-between">
              <v-col cols="6" class="d-flex flex-column justify-center"
                ><h1 class="font-weight-bold white--text">
                  Buy, trade, and hold 350+ cryptocurrencies on Axtoken
                </h1>
                <p>
                  Invest with just 1 USD. Build wealth with one app for stocks,
                  ETFs, savings plans, crypto and derivatives.
                </p>
                <div>
                  <v-btn
                    class="black--text"
                    color="secondary"
                    medium
                    to="/signin"
                    >Sign-in/Sign-up</v-btn
                  >
                </div>
              </v-col>
              <v-col cols="6" class="text-center">
                <img id="phones" src="@/assets/images/Landing.png" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <main-home-action-buttons />
        <main-home-market />
        <main-home-earn-coins class="my-12" />
        <!-- <main-home-feeds class="my-12" /> -->
        <main-home-build-portfolio class="my-12" />
        <main-home-download-app id="download" class="my-12" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  mounted() {
    this.$store.dispatch("setMarketCoins");
    // setInterval(() => {
    //     this.$store.dispatch("setMarketCoins");
    // }, 20000);
  },
  //Handle beforeRouteEnter with route hash
  beforeRouteEnter(to, from, next) {
    if (to.hash) {
      next((vm) => {
        vm.$nextTick(() => {
          const el = document.getElementById(to.hash.replace("#", ""));
          if (el) {
            //The top of the element will be aligned to the top of the viewport
            // const top = el.offsetTop;
            // console.log(top);
            // window.scrollTo({ top: top - 300, behavior: "smooth" });
            // el.scrollIntoView({ behavior: "smooth", block: "start" });
            //Scroll to the most bottom of the page
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            });
          }
        });
      });
    } else {
      next();
    }
  },
};
</script>
<style scoped>
.v-slide-group__prev {
  display: none !important;
}
a {
  color: rgb(209, 209, 209) !important;
}
.hero-section {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* height: 80vh; */
}
.full-height {
  height: 500px;
}
#phones {
  max-height: 600px; /* 50% of 280px */
  max-width: 600px; /* 50% of 280px */
  object-fit: contain;
}
</style>
