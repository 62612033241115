<template>
  <v-row justify="center">
    <v-col cols="5">
      <v-toolbar class="transparent elevation-0">
        <v-btn icon color="secondary" @click="goBack">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>History</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar>
      <v-row no-gutters class="mt-4">
        <v-tabs v-model="tab" color="secondary" background-color="#00000000">
          <v-tab href="#tab-0">Deposit</v-tab>
          <v-tab-item :value="'tab-0'">
            <v-card id="balance-card">
              <v-card-text>
                <div v-show="!loading">
                  <div v-if="depositHistory.length > 0">
                    <v-row
                      v-for="(history, index) in depositHistory"
                      :key="index"
                    >
                      <v-col cols="2" class="text-start">
                        <h6 class="caption font-weight-normal white--text">
                          {{ history.date | shortDate }}
                        </h6>
                      </v-col>
                      <v-col cols="2" class="text-start">
                        <h6 class="caption font-weight-normal white--text">
                          {{ history.coin }}
                        </h6>
                      </v-col>
                      <v-col cols="3" class="text-start">
                        <h6 class="caption font-weight-normal white--text">
                          {{ history.transaction_type }}
                        </h6>
                      </v-col>
                      <v-col cols="2" class="text-start">
                        <h6 class="caption font-weight-normal white--text">
                          {{ history.recharge_status }}
                        </h6>
                      </v-col>
                      <v-col cols="3" class="text-end">
                        <h6
                          class="caption font-weight-normal greenPrimary--text"
                        >
                          {{ history.recharge_amount | commaFormat }}
                        </h6>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row v-else class="text-center mt-12">
                    <h1>No Transactions</h1>
                  </v-row>
                </div>
                <div v-show="loading">
                  <v-row justify="center" class="text-center mt-12">
                    <v-progress-circular
                      indeterminate
                      color="secondary"
                    ></v-progress-circular>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab href="#tab-1">Withdrawal</v-tab>
          <v-tab-item :value="'tab-1'">
            <v-card id="balance-card">
              <v-card-text>
                <div v-show="!loading">
                  <div v-if="withdrawHistory.length > 0">
                    <v-row
                      v-for="(history, index) in withdrawHistory"
                      :key="index"
                    >
                      <v-col cols="2" class="text-start">
                        <h6 class="caption font-weight-normal white--text">
                          {{ history.date | shortDate }}
                        </h6>
                      </v-col>
                      <v-col cols="2" class="text-start">
                        <h6 class="caption font-weight-normal white--text">
                          {{ history.coin }}
                        </h6>
                      </v-col>
                      <v-col cols="3" class="text-start">
                        <h6 class="caption font-weight-normal white--text">
                          {{ history.withdraw_type }}
                        </h6>
                      </v-col>
                      <v-col cols="2" class="text-start">
                        <h6 class="caption font-weight-normal white--text">
                          {{ history.state }}
                        </h6>
                      </v-col>
                      <v-col cols="3" class="text-end">
                        <h6
                          class="caption font-weight-normal greenPrimary--text"
                        >
                          {{ history.withdraw_amount | commaFormat }}
                        </h6>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row v-else class="text-center mt-12">
                    <h1>No Transactions</h1>
                  </v-row>
                </div>
                <div v-show="loading">
                  <v-row justify="center" class="text-center mt-12">
                    <v-progress-circular
                      indeterminate
                      color="secondary"
                    ></v-progress-circular>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab href="#tab-2">Buy</v-tab>
          <v-tab-item :value="'tab-2'">
            <v-card id="balance-card">
              <v-card-text>
                <div v-show="!loading">
                  <div v-if="buyHistory.length > 0">
                    <v-row v-for="(history, index) in buyHistory" :key="index">
                      <v-col cols="3" class="text-start">
                        <h6 class="white--text">
                          {{ history.date | shortDate }}
                        </h6>
                      </v-col>
                      <v-col cols="3" class="text-start">
                        <h6 class="white--text">
                          {{ history.coin_pair }}
                        </h6>
                      </v-col>
                      <v-col cols="3" class="text-start">
                        <h6 class="white--text">
                          {{ history.state }}
                        </h6>
                      </v-col>
                      <v-col cols="3" class="text-end">
                        <h6 class="greenPrimary--text">
                          {{ history.final_price | commaFormat }}
                        </h6>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row v-else class="text-center mt-12">
                    <h1>No Transactions</h1>
                  </v-row>
                </div>
                <div v-show="loading">
                  <v-row justify="center" class="text-center mt-12">
                    <v-progress-circular
                      indeterminate
                      color="secondary"
                    ></v-progress-circular>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab href="#tab-3">Sell</v-tab>
          <v-tab-item :value="'tab-3'">
            <v-card id="balance-card">
              <v-card-text>
                <div v-show="!loading">
                  <div v-if="sellHistory.length > 0">
                    <v-row v-for="(history, index) in sellHistory" :key="index">
                      <v-col cols="3" class="text-start">
                        <h6 class="white--text">
                          {{ history.date | shortDate }}
                        </h6>
                      </v-col>
                      <v-col cols="3" class="text-start">
                        <h6 class="white--text">
                          {{ history.coin_pair }}
                        </h6>
                      </v-col>
                      <v-col cols="3" class="text-start">
                        <h6 class="white--text">
                          {{ history.state }}
                        </h6>
                      </v-col>
                      <v-col cols="3" class="text-end">
                        <h6 class="greenPrimary--text">
                          {{ history.final_price | commaFormat }}
                        </h6>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row v-else class="text-center mt-12">
                    <h1>No Transactions</h1>
                  </v-row>
                </div>
                <div v-show="loading">
                  <v-row justify="center" class="text-center mt-12">
                    <v-progress-circular
                      indeterminate
                      color="secondary"
                    ></v-progress-circular>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    tab: "tab-0",
    histories: [],
    depositHistory: [],
    withdrawHistory: [],
    sellHistory: [],
    buyHistory: [],
    loading: true,
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    fetchHistories() {
      this.loading = true;
      this.$axios
        .get("https://api.axtoken.net/api/v1/deposit-history")
        .then((response) => {
          this.depositHistory = response.data.deposit;
          this.loading = false;
        });
      this.$axios
        .get("https://api.axtoken.net/api/v1/withdraw-history")
        .then((response) => {
          this.withdrawHistory = response.data.withdraw;
          this.loading = false;
        });
      this.$axios
        .get("https://api.axtoken.net/api/v1/buy-history")
        .then((response) => {
          this.buyHistory = response.data.buy;
          this.loading = false;
        });
      this.$axios
        .get("https://api.axtoken.net/api/v1/sell-history")
        .then((response) => {
          this.sellHistory = response.data.sell;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchHistories();
  },
};
</script>
<style scoped>
p {
  font-size: smaller !important;
}

#balance-card {
  margin-top: 20px !important;
  border-radius: 30px 30px 0 0 !important;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>
