<template>
  <v-row justify="center">
    <v-col cols="5">
      <!-- <v-toolbar class="transparent elevation-0">
        <v-btn icon color="secondary" to="home">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Referral</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar> -->
      <v-container>
        <h2 class="mt-8 font-weight-bold">
          Refer Friends. Earn Crypto Together
        </h2>
        <p class="mt-4 subtitle-2">
          Invite your friends to join AX Token and earn 10% of their trading
          fees for life.
        </p>
        <!-- <v-card class="mt-12" rounded>
                <v-row justify="space-between" class="mx-3">
                    <v-col class="text-start">Total Bonus</v-col>
                    <v-col class="text-end">0 BTC</v-col>
                </v-row>
            </v-card> -->
        <v-card class="mt-12" rounded>
          <v-row justify="space-between" class="mx-3">
            <v-col cols="3" class="text-start">Referral Link</v-col>
            <v-col cols="9" class="text-end"
              >{{ referralCode
              }}<v-icon class="ml-2" @click="copyCode()"
                >mdi-content-copy</v-icon
              ></v-col
            >
          </v-row>
        </v-card>
        <v-card class="mt-12 transparent elevation-0" rounded>
          <v-card-text class="text-center">
            <div v-if="referralCode">
              <VueQrcode
                :value="referralCode"
                :options="{ width: 200 }"
              ></VueQrcode>
              <h2>Scan QR code</h2>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  components: {
    VueQrcode,
  },
  data: () => ({
    loading: false,
    referralCode: "",
  }),
  methods: {
    copyCode() {
      navigator.clipboard.writeText(this.referralCode);
      this.$swal({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Copied to clipboard",
        showConfirmButton: false,
        timer: 3000, // milliseconds
      });
    },
    fetchReferralCode() {
      this.loading = true;
      this.$axios
        .get("https://api.axtoken.net/api/v2/get-referral")
        .then((response) => {
          this.referralCode = `m.axtoken.com/signup/${response.data.referral}`;
        });
    },
  },
  mounted() {
    this.fetchReferralCode();
  },
};
</script>
<style>
/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
