<template>
  <v-row justify="center">
    <v-col cols="5">
      <!-- <v-toolbar>
        <v-btn icon color="secondary" to="account">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Security</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar> -->
      <v-container>
        <v-list class="transparent">
          <v-list-item
            class="transparent my-2"
            v-for="(item, index) in items"
            :key="index"
            :to="item.to"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <icon icon="fa:chevron-right" width="16" height="16" />
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    items: [
      {
        title: "Change Password",
        to: "change_password",
      },
      {
        title: "Change Transaction Password",
        to: "change_transaction_password",
      },
      {
        title: "Verify Account",
        to: "verify",
      },
    ],
  }),
  methods: {},
};
</script>
<style>
/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
