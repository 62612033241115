import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@mdi/font/css/materialdesignicons.css";
import { Icon } from "@iconify/vue2";
import "../assets/scss/vuetify-override.scss";
// import theme from "./theme";

Vue.use(Vuetify);
Vue.component("icon", Icon);

const opts = {
    // icons: { iconfont: ['fa', 'mdi']},
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: "#13617C",
                secondary: "#fcd535",
                accent: "#40626E",
                tertiary: "#1e1e1e",
                greyPrimary: "#2e2e2e",
                greenPrimary: "#10bd7d",
                redPrimary: "#f64649",
            },
        },
    },
};

export default new Vuetify(opts);
