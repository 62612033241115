var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"5"}},[(!_vm.loading)?_c('v-container',[(_vm.tasks.length > 0)?_c('div',_vm._l((_vm.tasks),function(task,index){return _c('v-card',{key:index,staticClass:"mt-4",attrs:{"disabled":task.reward_status == 'CLAIMED' || task.task_status == 'EXPIRED'
              ? true
              : false}},[_c('v-card-text',[_c('v-row',{staticClass:"ma-0",attrs:{"align":"start"},on:{"click":function($event){return _vm.coinClicked()}}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1"},[_c('h5',{staticClass:"font-weight-bold white--text"},[_vm._v(" "+_vm._s(task.task_center.description)+" ")])])],1),_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"7"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","label":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-ticket-confirmation ")]),_vm._v(" "+_vm._s(task.task_center.reward_amount)+" ")],1),_c('v-chip',{attrs:{"color":task.task_center.status == 'EXPIRED'
                          ? 'greyPrimary'
                          : 'secondary',"label":"","disabled":task.task_center.status == 'EXPIRED' ? true : false}},[_vm._v(" "+_vm._s(task.task_center.status)+" ")])],1),(task.task_status != 'EXPIRED')?_c('v-col',{staticClass:"px-0 text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","disabled":task.reward_status == 'CLAIMED' ||
                        task.task_status == 'ONGOING'
                          ? true
                          : false,"color":"greenPrimary"},on:{"click":function($event){return _vm.claimTask(task)}}},[_vm._v("Claim")])],1):_vm._e()],1)],1)],1)],1)],1)}),1):_c('div',[_c('v-card',{staticClass:"mt-4 transparent elevation-0"},[_c('v-card-text',{staticClass:"text-center"},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("No rewards available")])])],1)],1)],1)],1)],1)],1)]):_vm._e(),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},_vm._l((10),function(index){return _c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],key:index,staticClass:"mt-3",attrs:{"type":"list-item-three-line"}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }