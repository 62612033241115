<template>
  <v-container fluid>
    <v-row justify="center" align="start">
      <v-col cols="9" class="pa-0">
        <v-tabs v-model="tab" color="secondary" background-color="#00000000">
          <v-tab href="#tab-0">Overview</v-tab>
          <v-tab-item :value="'tab-0'" v-if="!overviewLoading">
            <v-row>
              <v-col>
                <main-wallet-overview-balance-card
                  :pieChartData="overviewPieChartData"
                  :pnl="pnl"
                />
              </v-col>
              <v-col>
                <main-wallet-overview-coins-card
                  :loading="overviewLoading"
                  :coins="walletBalances"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab href="#tab-1">Spot</v-tab>
          <v-tab-item :value="'tab-1'" v-if="!loading">
            <main-wallet-spot-balance-card
              :pieChartData="pieChartData"
              :balance="balance"
            />
            <main-wallet-spot-coins-card
              :loading="loading"
              :coins="walletCoins"
            />
          </v-tab-item>
          <v-tab-item :value="'tab-1'" v-else>
            <v-container full-screen-container>
              <v-row justify="center">
                <v-col cols="12" class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="secondary"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab href="#tab-3">Future</v-tab>
          <v-tab-item :value="'tab-3'">
            <main-wallet-future />
            <main-wallet-portfolio-card
              :loading="loading"
              :coins="futureWalletCoins"
            />
          </v-tab-item>
          <v-tab href="#tab-4">Earn</v-tab>
          <v-tab-item :value="'tab-4'">
            <main-wallet-staking />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      tab: "tab-1",
      loading: true,
      overviewLoading: true,
      wallet: {},
      pieChartData: {},
      overviewPieChartData: {},
      walletCoins: [],
      walletBalances: [],
      futureWalletCoins: {},
      balance: 0,
      pnl: {
        value: 0,
        percentage: 0,
      },
    };
  },
  watch: {
    tab: {
      handler: function (val) {
        this.$store.commit("setWalletView", val);
      },
    },
  },
  methods: {
    async fetchOverview() {
      await this.$store.dispatch("setWalletOverview");
      let wallets = this.$store.getters.walletOverview;
      let pnl = this.$store.getters.walletPnl;
      //Chart Data
      let overviewPieChartData = {
        series: [],
        labels: [],
      };
      let walletBalances = [];
      wallets.forEach((wallet) => {
        // if (wallet.usdt_value > 0) {
        overviewPieChartData.series.push(wallet.value);
        overviewPieChartData.labels.push(wallet.name);
        walletBalances.push({
          name: wallet.name,
          value: wallet.value,
        });
        // }
      });
      const todayPNL = {
        spot: pnl.spot_today,
        future: pnl.future_today,
        earn: pnl.earn_today,
      };
      const yesterdayPNL = {
        spot: parseFloat(pnl.spot_yesterday),
        future: parseFloat(pnl.future_yesterday),
        earn: parseFloat(pnl.earn_yesterday),
      };
      this.pnl = this.computeOverviewPNL(yesterdayPNL, todayPNL);
      console.log(this.pnl);
      //Balance
      this.walletBalances = walletBalances;
      this.overviewPieChartData = overviewPieChartData;
      this.overviewLoading = false;
    },

    async fetchSpotWallet() {
      await this.$store.dispatch("setWallet");
      this.wallet = this.$store.getters.wallet;
      //Chart Data
      let pieChartData = {
        series: [],
        labels: [],
      };
      let walletCoins = [];
      this.wallet.wallet.forEach((wallet) => {
        // if (wallet.usdt_value > 0) {
        pieChartData.series.push(wallet.usdt_value);
        pieChartData.labels.push(wallet.name);
        walletCoins.push({
          name: wallet.name,
          usdt_value: wallet.usdt_value,
          coin_value: wallet.coin_value,
          image: wallet.image,
        });
        // }
      });
      //Balance
      this.balance = this.wallet.usdtWallet[0].usdtWallet;
      this.walletCoins = walletCoins;
      this.pieChartData = pieChartData;
      this.loading = false;
    },

    async fetchFutureWallet() {
      //Add this wallet to vuex
      await this.$store.dispatch("setFutureWallet");
      this.futureWallet = this.$store.getters.futureWallet;
      let futureWalletCoins = [];
      this.futureWallet.wallet.forEach((wallet) => {
        futureWalletCoins.push({
          name: wallet.name,
          usdt_value: wallet.usdt_value,
          coin_value: wallet.coin_value,
          image: wallet.image,
        });
      });
      this.futureWalletCoins = futureWalletCoins;
    },

    computeOverviewPNL(yesterday, today) {
      const sumPNL = (pnlObj) => {
        return pnlObj.spot + pnlObj.future + pnlObj.earn;
      };

      const totalPNLYesterday = sumPNL(yesterday);
      const totalPNLToday = sumPNL(today);

      // Compute the difference to get the overview PNL
      const overviewPNLDifference = totalPNLToday - totalPNLYesterday;

      // Convert difference to percentage
      const percentageChange =
        totalPNLYesterday !== 0 // Check if totalPNLYesterday is not 0
          ? (overviewPNLDifference / totalPNLYesterday) * 100
          : 0;

      //Value should be rounded to 4 decimal places and with commas
      return {
        value: overviewPNLDifference.toFixed(4),
        percentage: percentageChange.toFixed(2),
      };
    },
  },

  mounted() {
    this.tab = this.$store.state.walletView;
    this.fetchOverview();
    this.fetchSpotWallet();
    this.fetchFutureWallet();
  },
};
</script>
<style>
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
.full-screen-container {
  height: 90vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
