<template>
  <v-row justify="center">
    <v-col cols="5">
      <v-toolbar class="transparent elevation-0">
        <v-btn icon color="secondary" @click="goBack">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Deposit USDT</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center">
            <div v-if="!loading">
              <VueQrcode
                v-if="address"
                :value="address"
                :options="{ width: 200 }"
              ></VueQrcode>
              <h2 v-else>No Data Available</h2>
            </div>
            <div v-else>
              <v-progress-circular
                indeterminate
                class="custom-loader"
                color="primary"
              ></v-progress-circular>
            </div>
            <p class="caption mt-3 grey--text darken-2">
              Send only USDT to this deposit address. This address does not
              support deposit of non-fungible token.
            </p>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10">
            <label>Wallet Address</label>
            <v-textarea
              v-model="address"
              dense
              readonly
              append-icon="mdi-content-copy"
              clickable
              @click:append="copyAddress()"
              rows="2"
            />
          </v-col>
          <v-col cols="10">
            <label>Network</label>
            <v-select
              v-model="selectedType"
              :items="walletTypes"
              item-text="name"
              item-value="name"
              dense
              solo
              @change="fetchAddress()"
            />
          </v-col>
          <v-col cols="10">
            <label>Minimum Deposit</label>
            <v-text-field
              v-model="
                walletTypes.find((item) => item.name === selectedType).minimum
              "
              dense
              disabled
              solo
            />
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  components: {
    VueQrcode,
  },
  data: () => ({
    loading: false,
    walletTypes: [
      { name: "TRC20", minimum: 1 },
      { name: "ERC20", minimum: 20 },
      { name: "OMNI", minimum: 1 },
    ],
    selectedType: "TRC20",
    address: null,
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async fetchAddress() {
      try {
        this.loading = true;
        const response = await this.$axios.get(
          "https://api.axtoken.net/api/v1/fetch/address",
          {
            params: {
              wallet_type: this.selectedType,
            },
          }
        );
        if (response.data.wallet)
          this.address = response.data.wallet.wallet_address;
        else this.address = null;
      } catch (error) {
        console.log(error);
        return [];
      } finally {
        this.loading = false;
      }
    },
    copyAddress() {
      navigator.clipboard.writeText(this.address);
      this.$swal({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Copied to clipboard",
        showConfirmButton: false,
        timer: 3000, // milliseconds
      });
    },
  },
  mounted() {
    this.fetchAddress();
  },
};
</script>
<style>
/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
