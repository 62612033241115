<template>
  <v-row justify="center">
    <v-col cols="11" xl="8">
      <main-markets-toolbar />
      <main-markets-market />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "market",
};
</script>
