import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";

//Home Routes
import Scaffold from "../components/main/Scaffold.vue";
import Landing from "../components/main/Landing.vue";
//Home
// import Home from "../views/main/Home/Home.vue";
// import Account from "../views/main/Home/Account.vue";
import Referral from "../views/main/Home/Referral.vue";
import Notifications from "../views/main/Home/Notifications.vue";
import Payments from "../views/main/Home/Payments.vue";
import Tasks from "../views/main/Home/Tasks.vue";
import Rewards from "../views/main/Home/Rewards.vue";
//Market
// import Markets from "../views/main/Markets/Markets.vue";
import TradingData from "../views/main/Markets/Markets.vue";
import Overview from "../views/main/Markets/Overview.vue";
// Settings
// import Settings from "../views/main/Settings/Settings.vue";
import Security from "../views/main/Settings/Security.vue";
import Profile from "../views/main/Settings/Profile.vue";
import ChangePassword from "../views/main/Settings/ChangePassword.vue";
import ChangeTransactionPassword from "../views/main/Settings/ChangeTransactionPassword.vue";
import Verify from "../views/main/Settings/Verify.vue";
//Trades
import Trades from "../views/main/Trades/Trades.vue";
import Futures from "../views/main/Trades/Futures.vue";
// import TradeChart from "../views/main/Trades/TradeChart.vue";
//Wallet
import Convert from "../views/main/Wallet/Convert.vue";
import Transfer from "../views/main/Wallet/Transfer.vue";
import Wallet from "../views/main/Wallet/Wallet.vue";
import Deposit from "../views/main/Wallet/Deposit.vue";
import Withdraw from "../views/main/Wallet/Withdraw.vue";
import Earn from "../views/main/Wallet/Earn.vue";
import SimpleEarn from "../views/main/Wallet/SimpleEarn.vue";
import AutoInvest from "../views/main/Wallet/AutoInvest.vue";
import Analysis from "../views/main/Wallet/Analysis.vue";
// import Transactions from "../views/main/Wallet/Transactions.vue";
import History from "../views/main/Wallet/History.vue";
// import Pay from "../views/main/Wallet/Pay.vue";
// import P2P from "../views/main/Wallet/P2P.vue";
// import Gift from "../views/main/Wallet/Gift.vue";
// import Pool from "../views/main/Wallet/Pool.vue";

Vue.use(VueRouter);

//Admin Routes
let mainRoutes = {
    component: Scaffold,
    path: "/",
    name: "Main",
    children: [
        {
            path: "/",
            name: "Landing",
            component: Landing,
        },
        //Accounts
        {
            path: '/account/referral',
            name: 'Referral',
            component: Referral,
        },
        {
            path: '/account/pay',
            name: 'Payment',
            component: Payments,
        },
        {
            path: '/account/notifications',
            name: 'Notification',
            component: Notifications,
        },
        {
            path: '/account/tasks',
            name: 'Task',
            component: Tasks,
        },
        {
            path: '/account/rewards',
            name: 'Reward',
            component: Rewards,
        },
        //Security
        {
            path: '/account/security',
            name: 'Security',
            component: Security,
        },
        {
            path: "/profile",
            name: "Profile",
            component: Profile,
        },
        {
            path:'/account/verify',
            name: 'Verify',
            component: Verify,
        },
        {
            path: '/account/change_password',
            name: 'ChangePassword',
            component: ChangePassword,
        },
        {
            path: '/account/change_transaction_password',
            name: 'ChangeTransactionPassword',
            component: ChangeTransactionPassword,
        },
        //Markets
        {
            path: "/markets/trading_data",
            name: "TradingData",
            component: TradingData,
        },
        {
            path: "/markets/overview",
            name: "Overview",
            component: Overview,
        },
        //Trades
        {
            path: "/trades/spot/:coin?",
            name: "Trades",
            component: Trades,
        },
        {
            path: "/trades/futures/:coin?",
            name: "Futures",
            component: Futures,
        },
        //Wallet
        {
            path: "/wallet",
            name: "Wallet",
            component: Wallet,
        },
        {
            path: "/wallet/deposit",
            name: "Deposit",
            component: Deposit,
        },
        {
            path: "/wallet/withdraw",
            name: "Withdraw",
            component: Withdraw,
        },
        {
            path: "/wallet/convert",
            name: "Convert",
            component: Convert,
        },
        {
            path: "/wallet/transfer",
            name: "Transfer",
            component: Transfer,
        },
        {
            path: "/history",
            name: "History",
            component: History,
        },
        {
            path: "/wallet/earn",
            name: "Earn",
            component: Earn,
        },
        {
            path: "/wallet/simple_earn",
            name: "SimpleEarn",
            component: SimpleEarn,
        },
        {
            path: "/wallet/auto_invest",
            name: "AutoInvest",
            component: AutoInvest,
        },
        {
            path: "/wallet/analysis",
            name: "Analysis",
            component: Analysis,
        },
        //Authentications
        {
            path: "/signin",
            name: "SignIn",
            component: SignIn,
        },
        {
            path: "/signup/:referralCode?",
            name: "SignUp",
            component: SignUp,
        },
        //About
        {
            path: "/terms",
            name: "Terms",
            component: () => import("../views/main/About/Terms.vue"),
        },
        {
            path: "/privacy",
            name: "Privacy",
            component: () => import("../views/main/About/Privacy.vue"),
        },
        {
            path: "/contactus",
            name: "Contact",
            component: () => import("../views/main/About/Contact.vue"),
            
        },
        {
            path: '/about',
            name: "About",
            component: () => import('../views/main/About/About.vue')
        }
    ],
};

const routes = [
    mainRoutes,
];

const router = new VueRouter({
    routes,
    mode: "history",
    base: process.env.BASE_URL,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated;

    if (isAuthenticated && (to.name === "SignIn" || to.name === "SignUp")) {
        next({ name: "Landing" });
    } else {
        next();
    }
});

export default router;
