<template>
    <div>
        <v-row justify="center">
            <v-col
                cols="12"
                lg="4"
                v-for="(coins, key) in filteredCoins"
                :key="key"
            >
                <v-container
                    v-if="
                        Object.values(filteredCoins).some(
                            (coinArray) => coinArray.length > 0
                        )
                    "
                    class="mt-4 market-containers"
                >
                    <h5 class="secondary--text text-center">{{ key }} Coins</h5>
                    <main-markets-market-coin
                        v-for="coin in coins"
                        :key="coin.id"
                        :name="coin.name"
                        :image="coin.image"
                        :percent="coin.percent"
                        :amount="coin.amount"
                    />
                </v-container>
                <v-row v-else justify="center">
                    <v-col cols="12" class="text-center">
                        <h3 class="mt-5">No coins found</h3>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    mounted() {},
    computed: {
        marketCoins() {
            return this.$store.getters.marketCoins;
        },
        filteredCoins() {
            return {
                MAIN: this.marketCoins.filter((coin) => coin.type === "MAIN"),
                ETH: this.marketCoins.filter((coin) => coin.type === "ETH"),
                ALT: this.marketCoins.filter((coin) => coin.type === "ALT"),
            };
        },
    },
};
</script>
<style scoped>
.v-tab {
    min-width: 60px !important;
}
.theme--dark.v-tabs-items {
    background-color: transparent !important;
}
.market-containers {
    border-radius: 20px !important;
    background-color: #1e1e1e !important;
}
</style>
