<template>
  <v-container class="signin-container" fluid>
    <v-btn
      v-if="step != 0"
      class="mt-6 font-weight-bold"
      color="primary"
      text
      @click="step == 1 ? (step = 0) : (step = 1)"
      ><v-icon left large color="primary">mdi-arrow-left</v-icon>
    </v-btn>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card v-if="step == 0" class="mt-12 elevation-0 transparent">
          <v-card-text class="mt-8 text-center">
            <!-- <v-row align="center" justify="center" class="mb-1"> -->
            <img
              src="@/assets/images/ax-logo.png"
              max-height="280"
              max-width="280"
            />
            <!-- </v-row> -->
            <v-row align="center" justify="center" class="my-2">
              <h1 class="display-1">Sign up to get exclusive discounts</h1>
            </v-row>
            <v-btn
              color="primary"
              class="mt-6"
              rounded
              large
              block
              @click="step = 1"
            >
              Sign Up with Email or Phone
            </v-btn>
            <!-- <div class="d-flex justify-center align-center mt-6">
              <v-divider dark />
              <span class="text-center mx-3 font-weight-bold">OR</span>
              <v-divider dark />
            </div>
            <v-btn class="mt-6" rounded large block @click="step = 1">
              <v-icon left> mdi-google </v-icon>Continue with Google
            </v-btn> -->
            <v-row class="mt-6">
              <h6>
                Already have an account?
                <a @click="goTo('/signin')"
                  ><strong class="secondary--text">Sign-in</strong></a
                >
              </h6>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-else-if="step == 1" class="mt-12 elevation-0 transparent">
          <v-card-text>
            <h2 class="display-2 text-center">Axtoken SignUp</h2>
            <v-form class="mt-8" ref="signUpForm">
              <v-row align="center">
                <v-col cols="4">
                  <label>Country Code</label>
                  <v-autocomplete
                    auto-select-first
                    v-model="selectedCountryCode"
                    :items="countryCodes"
                    item-text="dial_code"
                    item-value="dial_code"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="8">
                  <label>Phone Number</label>
                  <v-text-field
                    v-model="phoneNumber"
                    type="tel"
                    required
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="referral"
                    type="text"
                    label="Referral Code"
                    required
                    :disabled="referred"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    type="text"
                    label="Email"
                    required
                    :rules="rules.emailRules"
                    :error-messages="errorMessages.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="password"
                    label="Password"
                    :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="visible = !visible"
                    :rules="rules.passwordRules"
                    :type="visible ? 'text' : 'password'"
                    @keydown.enter="sendCode()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                color="primary"
                class="mt-6"
                rounded
                large
                block
                @click="sendCode(), (loader = 'loading')"
                :loading="loading"
                :disabled="loading"
              >
                Next
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-form>
            <v-divider dark />
            <v-btn
              to="/signin"
              class="mt-2 font-weight-bold pl-0"
              color="primary"
              block
              text
              >Sign In</v-btn
            >
          </v-card-text>
        </v-card>
        <v-card
          v-else
          transition="slide-x-reverse-transition"
          class="card-position"
        >
          <v-toolbar class="text-center elevation-0" height="90">
            <v-spacer></v-spacer>
            <div>
              <v-row>
                <v-toolbar-title class="display-1"
                  >Verification</v-toolbar-title
                >
              </v-row>
            </div>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="mt-8">
            <v-form ref="signUpForm2">
              <label>Verification Code</label>
              <v-row align="center">
                <v-col cols="12">
                  <v-text-field
                    v-model="verification_code"
                    :rules="rules.required"
                    type="text"
                    @keydown.enter="register()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                color="primary"
                class="mt-6"
                rounded
                large
                block
                @click="register(), (loader = 'loading')"
                :loading="loading"
                :disabled="loading"
              >
                Sign-Up
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      step: 0,
      visible: false,
      email: "",
      phoneNumber: "",
      referral: "",
      password: "",
      selectedCountryCode: "+63",
      verification_code: "",
      errorMessages: {
        email: "",
      },
      rules: {
        //Required Rules
        required: [(v) => !!v || "This field is required"],
        //Form Field Rules
        phoneNumberRule: [
          (v) =>
            v.startsWith(this.selectedCountryCode) ||
            "Please enter a valid phone number",
        ],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        passwordRule: [
          (v) => v.length >= 6 || "Password must be at least 6 characters",
        ],
      },
      countryCodes: [
        {
          name: "Afghanistan",
          dial_code: "+93",
          emoji: "🇦🇫",
          code: "AF",
        },
        {
          name: "Aland Islands",
          dial_code: "+358",
          emoji: "🇦🇽",
          code: "AX",
        },
        {
          name: "Albania",
          dial_code: "+355",
          emoji: "🇦🇱",
          code: "AL",
        },
        {
          name: "Algeria",
          dial_code: "+213",
          emoji: "🇩🇿",
          code: "DZ",
        },
        {
          name: "AmericanSamoa",
          dial_code: "+1684",
          emoji: "🇦🇸",
          code: "AS",
        },
        {
          name: "Andorra",
          dial_code: "+376",
          emoji: "🇦🇩",
          code: "AD",
        },
        {
          name: "Angola",
          dial_code: "+244",
          emoji: "🇦🇴",
          code: "AO",
        },
        {
          name: "Anguilla",
          dial_code: "+1264",
          emoji: "🇦🇮",
          code: "AI",
        },
        {
          name: "Antarctica",
          dial_code: "+672",
          emoji: "🇦🇶",
          code: "AQ",
        },
        {
          name: "Antigua and Barbuda",
          dial_code: "+1268",
          emoji: "🇦🇬",
          code: "AG",
        },
        {
          name: "Argentina",
          dial_code: "+54",
          emoji: "🇦🇷",
          code: "AR",
        },
        {
          name: "Armenia",
          dial_code: "+374",
          emoji: "🇦🇲",
          code: "AM",
        },
        {
          name: "Aruba",
          dial_code: "+297",
          emoji: "🇦🇼",
          code: "AW",
        },
        {
          name: "Australia",
          dial_code: "+61",
          emoji: "🇦🇺",
          code: "AU",
        },
        {
          name: "Austria",
          dial_code: "+43",
          emoji: "🇦🇹",
          code: "AT",
        },
        {
          name: "Azerbaijan",
          dial_code: "+994",
          emoji: "🇦🇿",
          code: "AZ",
        },
        {
          name: "Bahamas",
          dial_code: "+1242",
          emoji: "🇧🇸",
          code: "BS",
        },
        {
          name: "Bahrain",
          dial_code: "+973",
          emoji: "🇧🇭",
          code: "BH",
        },
        {
          name: "Bangladesh",
          dial_code: "+880",
          emoji: "🇧🇩",
          code: "BD",
        },
        {
          name: "Barbados",
          dial_code: "+1246",
          emoji: "🇧🇧",
          code: "BB",
        },
        {
          name: "Belarus",
          dial_code: "+375",
          emoji: "🇧🇾",
          code: "BY",
        },
        {
          name: "Belgium",
          dial_code: "+32",
          emoji: "🇧🇪",
          code: "BE",
        },
        {
          name: "Belize",
          dial_code: "+501",
          emoji: "🇧🇿",
          code: "BZ",
        },
        {
          name: "Benin",
          dial_code: "+229",
          emoji: "🇧🇯",
          code: "BJ",
        },
        {
          name: "Bermuda",
          dial_code: "+1441",
          emoji: "🇧🇲",
          code: "BM",
        },
        {
          name: "Bhutan",
          dial_code: "+975",
          emoji: "🇧🇹",
          code: "BT",
        },
        {
          name: "Bolivia, Plurinational State of",
          dial_code: "+591",
          emoji: "🇧🇴",
          code: "BO",
        },
        {
          name: "Bosnia and Herzegovina",
          dial_code: "+387",
          emoji: "🇧🇦",
          code: "BA",
        },
        {
          name: "Botswana",
          dial_code: "+267",
          emoji: "🇧🇼",
          code: "BW",
        },
        {
          name: "Brazil",
          dial_code: "+55",
          emoji: "🇧🇷",
          code: "BR",
        },
        {
          name: "British Indian Ocean Territory",
          dial_code: "+246",
          emoji: "🇮🇴",
          code: "IO",
        },
        {
          name: "Brunei Darussalam",
          dial_code: "+673",
          emoji: "🇧🇳",
          code: "BN",
        },
        {
          name: "Bulgaria",
          dial_code: "+359",
          emoji: "🇧🇬",
          code: "BG",
        },
        {
          name: "Burkina Faso",
          dial_code: "+226",
          emoji: "🇧🇫",
          code: "BF",
        },
        {
          name: "Burundi",
          dial_code: "+257",
          emoji: "🇧🇮",
          code: "BI",
        },
        {
          name: "Cambodia",
          dial_code: "+855",
          emoji: "🇰🇭",
          code: "KH",
        },
        {
          name: "Cameroon",
          dial_code: "+237",
          emoji: "🇨🇲",
          code: "CM",
        },
        {
          name: "Canada",
          dial_code: "+1",
          emoji: "🇨🇦",
          code: "CA",
        },
        {
          name: "Cape Verde",
          dial_code: "+238",
          emoji: "🇨🇻",
          code: "CV",
        },
        {
          name: "Cayman Islands",
          dial_code: "+ 345",
          emoji: "🇰🇾",
          code: "KY",
        },
        {
          name: "Central African Republic",
          dial_code: "+236",
          emoji: "🇨🇫",
          code: "CF",
        },
        {
          name: "Chad",
          dial_code: "+235",
          emoji: "🇹🇩",
          code: "TD",
        },
        {
          name: "Chile",
          dial_code: "+56",
          emoji: "🇨🇱",
          code: "CL",
        },
        {
          name: "China",
          dial_code: "+86",
          emoji: "🇨🇳",
          code: "CN",
        },
        {
          name: "Christmas Island",
          dial_code: "+61",
          emoji: "🇨🇽",
          code: "CX",
        },
        {
          name: "Cocos (Keeling) Islands",
          dial_code: "+61",
          emoji: "🇨🇨",
          code: "CC",
        },
        {
          name: "Colombia",
          dial_code: "+57",
          emoji: "🇨🇴",
          code: "CO",
        },
        {
          name: "Comoros",
          dial_code: "+269",
          emoji: "🇰🇲",
          code: "KM",
        },
        {
          name: "Congo",
          dial_code: "+242",
          emoji: "🇨🇬",
          code: "CG",
        },
        {
          name: "Congo, The Democratic Republic of the Congo",
          dial_code: "+243",
          emoji: "🇨🇩",
          code: "CD",
        },
        {
          name: "Cook Islands",
          dial_code: "+682",
          emoji: "🇨🇰",
          code: "CK",
        },
        {
          name: "Costa Rica",
          dial_code: "+506",
          emoji: "🇨🇷",
          code: "CR",
        },
        {
          name: "Cote d'Ivoire",
          dial_code: "+225",
          emoji: "🇨🇮",
          code: "CI",
        },
        {
          name: "Croatia",
          dial_code: "+385",
          emoji: "🇭🇷",
          code: "HR",
        },
        {
          name: "Cuba",
          dial_code: "+53",
          emoji: "🇨🇺",
          code: "CU",
        },
        {
          name: "Cyprus",
          dial_code: "+357",
          emoji: "🇨🇾",
          code: "CY",
        },
        {
          name: "Czech Republic",
          dial_code: "+420",
          emoji: "🇨🇿",
          code: "CZ",
        },
        {
          name: "Denmark",
          dial_code: "+45",
          emoji: "🇩🇰",
          code: "DK",
        },
        {
          name: "Djibouti",
          dial_code: "+253",
          emoji: "🇩🇯",
          code: "DJ",
        },
        {
          name: "Dominica",
          dial_code: "+1767",
          emoji: "🇩🇲",
          code: "DM",
        },
        {
          name: "Dominican Republic",
          dial_code: "+1849",
          emoji: "🇩🇴",
          code: "DO",
        },
        {
          name: "Ecuador",
          dial_code: "+593",
          emoji: "🇪🇨",
          code: "EC",
        },
        {
          name: "Egypt",
          dial_code: "+20",
          emoji: "🇪🇬",
          code: "EG",
        },
        {
          name: "El Salvador",
          dial_code: "+503",
          emoji: "🇸🇻",
          code: "SV",
        },
        {
          name: "Equatorial Guinea",
          dial_code: "+240",
          emoji: "🇬🇶",
          code: "GQ",
        },
        {
          name: "Eritrea",
          dial_code: "+291",
          emoji: "🇪🇷",
          code: "ER",
        },
        {
          name: "Estonia",
          dial_code: "+372",
          emoji: "🇪🇪",
          code: "EE",
        },
        {
          name: "Ethiopia",
          dial_code: "+251",
          emoji: "🇪🇹",
          code: "ET",
        },
        {
          name: "Falkland Islands (Malvinas)",
          dial_code: "+500",
          emoji: "🇫🇰",
          code: "FK",
        },
        {
          name: "Faroe Islands",
          dial_code: "+298",
          emoji: "🇫🇴",
          code: "FO",
        },
        {
          name: "Fiji",
          dial_code: "+679",
          emoji: "🇫🇯",
          code: "FJ",
        },
        {
          name: "Finland",
          dial_code: "+358",
          emoji: "🇫🇮",
          code: "FI",
        },
        {
          name: "France",
          dial_code: "+33",
          emoji: "🇫🇷",
          code: "FR",
        },
        {
          name: "French Guiana",
          dial_code: "+594",
          emoji: "🇬🇫",
          code: "GF",
        },
        {
          name: "French Polynesia",
          dial_code: "+689",
          emoji: "🇵🇫",
          code: "PF",
        },
        {
          name: "Gabon",
          dial_code: "+241",
          emoji: "🇬🇦",
          code: "GA",
        },
        {
          name: "Gambia",
          dial_code: "+220",
          emoji: "🇬🇲",
          code: "GM",
        },
        {
          name: "Georgia",
          dial_code: "+995",
          emoji: "🇬🇪",
          code: "GE",
        },
        {
          name: "Germany",
          dial_code: "+49",
          emoji: "🇩🇪",
          code: "DE",
        },
        {
          name: "Ghana",
          dial_code: "+233",
          emoji: "🇬🇭",
          code: "GH",
        },
        {
          name: "Gibraltar",
          dial_code: "+350",
          emoji: "🇬🇮",
          code: "GI",
        },
        {
          name: "Greece",
          dial_code: "+30",
          emoji: "🇬🇷",
          code: "GR",
        },
        {
          name: "Greenland",
          dial_code: "+299",
          emoji: "🇬🇱",
          code: "GL",
        },
        {
          name: "Grenada",
          dial_code: "+1473",
          emoji: "🇬🇩",
          code: "GD",
        },
        {
          name: "Guadeloupe",
          dial_code: "+590",
          emoji: "🇬🇵",
          code: "GP",
        },
        {
          name: "Guam",
          dial_code: "+1671",
          emoji: "🇬🇺",
          code: "GU",
        },
        {
          name: "Guatemala",
          dial_code: "+502",
          emoji: "🇬🇹",
          code: "GT",
        },
        {
          name: "Guernsey",
          dial_code: "+44",
          emoji: "🇬🇬",
          code: "GG",
        },
        {
          name: "Guinea",
          dial_code: "+224",
          emoji: "🇬🇳",
          code: "GN",
        },
        {
          name: "Guinea-Bissau",
          dial_code: "+245",
          emoji: "🇬🇼",
          code: "GW",
        },
        {
          name: "Guyana",
          dial_code: "+595",
          emoji: "🇬🇾",
          code: "GY",
        },
        {
          name: "Haiti",
          dial_code: "+509",
          emoji: "🇭🇹",
          code: "HT",
        },
        {
          name: "Holy See (Vatican City State)",
          dial_code: "+379",
          emoji: "🇻🇦",
          code: "VA",
        },
        {
          name: "Honduras",
          dial_code: "+504",
          emoji: "🇭🇳",
          code: "HN",
        },
        {
          name: "Hong Kong",
          dial_code: "+852",
          emoji: "🇭🇰",
          code: "HK",
        },
        {
          name: "Hungary",
          dial_code: "+36",
          emoji: "🇭🇺",
          code: "HU",
        },
        {
          name: "Iceland",
          dial_code: "+354",
          emoji: "🇮🇸",
          code: "IS",
        },
        {
          name: "India",
          dial_code: "+91",
          emoji: "🇮🇳",
          code: "IN",
        },
        {
          name: "Indonesia",
          dial_code: "+62",
          emoji: "🇮🇩",
          code: "ID",
        },
        {
          name: "Iran, Islamic Republic of Persian Gulf",
          dial_code: "+98",
          emoji: "🇮🇷",
          code: "IR",
        },
        {
          name: "Iraq",
          dial_code: "+964",
          emoji: "🇮🇷",
          code: "IQ",
        },
        {
          name: "Ireland",
          dial_code: "+353",
          emoji: "🇮🇪",
          code: "IE",
        },
        {
          name: "Isle of Man",
          dial_code: "+44",
          emoji: "🇮🇲",
          code: "IM",
        },
        {
          name: "Israel",
          dial_code: "+972",
          emoji: "🇮🇱",
          code: "IL",
        },
        {
          name: "Italy",
          dial_code: "+39",
          emoji: "🇮🇹",
          code: "IT",
        },
        {
          name: "Jamaica",
          dial_code: "+1876",
          emoji: "🇯🇲",
          code: "JM",
        },
        {
          name: "Japan",
          dial_code: "+81",
          emoji: "🇯🇵",
          code: "JP",
        },
        {
          name: "Jersey",
          dial_code: "+44",
          emoji: "🇯🇪",
          code: "JE",
        },
        {
          name: "Jordan",
          dial_code: "+962",
          emoji: "🇯🇴",
          code: "JO",
        },
        {
          name: "Kazakhstan",
          dial_code: "+77",
          emoji: "🇰🇿",
          code: "KZ",
        },
        {
          name: "Kenya",
          dial_code: "+254",
          emoji: "🇰🇪",
          code: "KE",
        },
        {
          name: "Kiribati",
          dial_code: "+686",
          emoji: "🇰🇮",
          code: "KI",
        },
        {
          name: "Korea, Democratic People's Republic of Korea",
          dial_code: "+850",
          emoji: "🇰🇵",
          code: "KP",
        },
        {
          name: "Korea, Republic of South Korea",
          dial_code: "+82",
          emoji: "🇰🇷",
          code: "KR",
        },
        {
          name: "Kuwait",
          dial_code: "+965",
          emoji: "🇰🇼",
          code: "KW",
        },
        {
          name: "Kyrgyzstan",
          dial_code: "+996",
          emoji: "🇰🇬",
          code: "KG",
        },
        {
          name: "Laos",
          dial_code: "+856",
          emoji: "🇱🇦",
          code: "LA",
        },
        {
          name: "Latvia",
          dial_code: "+371",
          emoji: "🇱🇻",
          code: "LV",
        },
        {
          name: "Lebanon",
          dial_code: "+961",
          emoji: "🇱🇧",
          code: "LB",
        },
        {
          name: "Lesotho",
          dial_code: "+266",
          emoji: "🇱🇸",
          code: "LS",
        },
        {
          name: "Liberia",
          dial_code: "+231",
          emoji: "🇱🇷",
          code: "LR",
        },
        {
          name: "Libyan Arab Jamahiriya",
          dial_code: "+218",
          emoji: "🇱🇾",
          code: "LY",
        },
        {
          name: "Liechtenstein",
          dial_code: "+423",
          emoji: "🇱🇮",
          code: "LI",
        },
        {
          name: "Lithuania",
          dial_code: "+370",
          emoji: "🇱🇹",
          code: "LT",
        },
        {
          name: "Luxembourg",
          dial_code: "+352",
          emoji: "🇱🇺",
          code: "LU",
        },
        {
          name: "Macao",
          dial_code: "+853",
          emoji: "🇲🇴",
          code: "MO",
        },
        {
          name: "Macedonia",
          dial_code: "+389",
          emoji: "🇲🇰",
          code: "MK",
        },
        {
          name: "Madagascar",
          dial_code: "+261",
          emoji: "🇲🇬",
          code: "MG",
        },
        {
          name: "Malawi",
          dial_code: "+265",
          emoji: "🇲🇼",
          code: "MW",
        },
        {
          name: "Malaysia",
          dial_code: "+60",
          emoji: "🇲🇾",
          code: "MY",
        },
        {
          name: "Maldives",
          dial_code: "+960",
          emoji: "🇲🇻",
          code: "MV",
        },
        {
          name: "Mali",
          dial_code: "+223",
          emoji: "🇲🇱",
          code: "ML",
        },
        {
          name: "Malta",
          dial_code: "+356",
          emoji: "🇲🇹",
          code: "MT",
        },
        {
          name: "Marshall Islands",
          dial_code: "+692",
          emoji: "🇲🇭",
          code: "MH",
        },
        {
          name: "Martinique",
          dial_code: "+596",
          emoji: "🇲🇶",
          code: "MQ",
        },
        {
          name: "Mauritania",
          dial_code: "+222",
          emoji: "🇲🇷",
          code: "MR",
        },
        {
          name: "Mauritius",
          dial_code: "+230",
          emoji: "🇲🇺",
          code: "MU",
        },
        {
          name: "Mayotte",
          dial_code: "+262",
          emoji: "🇾🇹",
          code: "YT",
        },
        {
          name: "Mexico",
          dial_code: "+52",
          emoji: "🇲🇽",
          code: "MX",
        },
        {
          name: "Micronesia, Federated States of Micronesia",
          dial_code: "+691",
          emoji: "🇫🇲",
          code: "FM",
        },
        {
          name: "Moldova",
          dial_code: "+373",
          emoji: "🇲🇩",
          code: "MD",
        },
        {
          name: "Monaco",
          dial_code: "+377",
          emoji: "🇲🇨",
          code: "MC",
        },
        {
          name: "Mongolia",
          dial_code: "+976",
          emoji: "🇲🇳",
          code: "MN",
        },
        {
          name: "Montenegro",
          dial_code: "+382",
          emoji: "🇲🇪",
          code: "ME",
        },
        {
          name: "Montserrat",
          dial_code: "+1664",
          emoji: "🇲🇸",
          code: "MS",
        },
        {
          name: "Morocco",
          dial_code: "+212",
          emoji: "🇲🇦",
          code: "MA",
        },
        {
          name: "Mozambique",
          dial_code: "+258",
          emoji: "🇲🇿",
          code: "MZ",
        },
        {
          name: "Myanmar",
          dial_code: "+95",
          emoji: "🇲🇲",
          code: "MM",
        },
        {
          name: "Namibia",
          emoji: "🇳🇦",
          dial_code: "+264",
          code: "NA",
        },
        {
          name: "Nauru",
          dial_code: "+674",
          emoji: "🇳🇷",
          code: "NR",
        },
        {
          name: "Nepal",
          dial_code: "+977",
          emoji: "🇳🇵",
          code: "NP",
        },
        {
          name: "Netherlands",
          dial_code: "+31",
          emoji: "🇳🇱",
          code: "NL",
        },
        {
          name: "Netherlands Antilles",
          dial_code: "+599",
          emoji: "🇧🇶",
          code: "AN",
        },
        {
          name: "New Caledonia",
          dial_code: "+687",
          emoji: "🇳🇨",
          code: "NC",
        },
        {
          name: "New Zealand",
          dial_code: "+64",
          emoji: "🇳🇿",
          code: "NZ",
        },
        {
          name: "Nicaragua",
          dial_code: "+505",
          emoji: "🇳🇮",
          code: "NI",
        },
        {
          name: "Niger",
          dial_code: "+227",
          emoji: "🇳🇪",
          code: "NE",
        },
        {
          name: "Nigeria",
          dial_code: "+234",
          emoji: "🇳🇬",
          code: "NG",
        },
        {
          name: "Niue",
          dial_code: "+683",
          emoji: "🇳🇺",
          code: "NU",
        },
        {
          name: "Norfolk Island",
          dial_code: "+672",
          emoji: "🇳🇫",
          code: "NF",
        },
        {
          name: "Northern Mariana Islands",
          dial_code: "+1670",
          emoji: "🇲🇵",
          code: "MP",
        },
        {
          name: "Norway",
          dial_code: "+47",
          emoji: "🇳🇴",
          code: "NO",
        },
        {
          name: "Oman",
          dial_code: "+968",
          emoji: "🇴🇲",
          code: "OM",
        },
        {
          name: "Pakistan",
          dial_code: "+92",
          emoji: "🇵🇰",
          code: "PK",
        },
        {
          name: "Palau",
          dial_code: "+680",
          emoji: "🇵🇼",
          code: "PW",
        },
        {
          name: "Palestinian Territory, Occupied",
          dial_code: "+970",
          emoji: "🇵🇸",
          code: "PS",
        },
        {
          name: "Panama",
          dial_code: "+507",
          emoji: "🇵🇦",
          code: "PA",
        },
        {
          name: "Papua New Guinea",
          dial_code: "+675",
          emoji: "🇵🇬",
          code: "PG",
        },
        {
          name: "Paraguay",
          dial_code: "+595",
          emoji: "🇵🇾",
          code: "PY",
        },
        {
          name: "Peru",
          dial_code: "+51",
          emoji: "🇵🇪",
          code: "PE",
        },
        {
          name: "Philippines",
          dial_code: "+63",
          emoji: "🇵🇭",
          code: "PH",
        },
        {
          name: "Pitcairn",
          dial_code: "+872",
          emoji: "🇵🇳",
          code: "PN",
        },
        {
          name: "Poland",
          dial_code: "+48",
          emoji: "🇵🇱",
          code: "PL",
        },
        {
          name: "Portugal",
          dial_code: "+351",
          emoji: "🇵🇹",
          code: "PT",
        },
        {
          name: "Puerto Rico",
          dial_code: "+1939",
          emoji: "🇵🇷",
          code: "PR",
        },
        {
          name: "Qatar",
          dial_code: "+974",
          emoji: "🇶🇦",
          code: "QA",
        },
        {
          name: "Romania",
          dial_code: "+40",
          emoji: "🇷🇴",
          code: "RO",
        },
        {
          name: "Russia",
          dial_code: "+7",
          emoji: "🇷🇺",
          code: "RU",
        },
        {
          name: "Rwanda",
          dial_code: "+250",
          emoji: "🇷🇼",
          code: "RW",
        },
        {
          name: "Reunion",
          dial_code: "+262",
          emoji: "🇷🇪",
          code: "RE",
        },
        {
          name: "Saint Barthelemy",
          dial_code: "+590",
          emoji: "🇧🇱",
          code: "BL",
        },
        {
          name: "Saint Helena, Ascension and Tristan Da Cunha",
          dial_code: "+290",
          emoji: "🇸🇭",
          code: "SH",
        },
        {
          name: "Saint Kitts and Nevis",
          dial_code: "+1869",
          emoji: "🇰🇳",
          code: "KN",
        },
        {
          name: "Saint Lucia",
          dial_code: "+1758",
          emoji: "🇱🇨",
          code: "LC",
        },
        {
          name: "Saint Martin",
          dial_code: "+590",
          emoji: "🇲🇫",
          code: "MF",
        },
        {
          name: "Saint Pierre and Miquelon",
          dial_code: "+508",
          emoji: "🇵🇲",
          code: "PM",
        },
        {
          name: "Saint Vincent and the Grenadines",
          dial_code: "+1784",
          emoji: "🇻🇨",
          code: "VC",
        },
        {
          name: "Samoa",
          dial_code: "+685",
          emoji: "🇼🇸",
          code: "WS",
        },
        {
          name: "San Marino",
          dial_code: "+378",
          emoji: "🇸🇲",
          code: "SM",
        },
        {
          name: "Sao Tome and Principe",
          dial_code: "+239",
          emoji: "🇸🇹",
          code: "ST",
        },
        {
          name: "Saudi Arabia",
          dial_code: "+966",
          emoji: "🇸🇦",
          code: "SA",
        },
        {
          name: "Senegal",
          dial_code: "+221",
          emoji: "🇸🇳",
          code: "SN",
        },
        {
          name: "Serbia",
          dial_code: "+381",
          emoji: "🇷🇸",
          code: "RS",
        },
        {
          name: "Seychelles",
          dial_code: "+248",
          emoji: "🇸🇨",
          code: "SC",
        },
        {
          name: "Sierra Leone",
          dial_code: "+232",
          emoji: "🇸🇱",
          code: "SL",
        },
        {
          name: "Singapore",
          dial_code: "+65",
          emoji: "🇸🇬",
          code: "SG",
        },
        {
          name: "Slovakia",
          dial_code: "+421",
          emoji: "🇸🇰",
          code: "SK",
        },
        {
          name: "Slovenia",
          dial_code: "+386",
          emoji: "🇸🇮",
          code: "SI",
        },
        {
          name: "Solomon Islands",
          dial_code: "+677",
          emoji: "🇸🇧",
          code: "SB",
        },
        {
          name: "Somalia",
          dial_code: "+252",
          emoji: "🇸🇴",
          code: "SO",
        },
        {
          name: "South Africa",
          dial_code: "+27",
          emoji: "🇿🇦",
          code: "ZA",
        },
        {
          name: "South Sudan",
          dial_code: "+211",
          emoji: "🇸🇸",
          code: "SS",
        },
        {
          name: "South Georgia and the South Sandwich Islands",
          dial_code: "+500",
          emoji: "🇬🇸",
          code: "GS",
        },
        {
          name: "Spain",
          dial_code: "+34",
          emoji: "🇪🇸",
          code: "ES",
        },
        {
          name: "Sri Lanka",
          dial_code: "+94",
          emoji: "🇱🇰",
          code: "LK",
        },
        {
          name: "Sudan",
          dial_code: "+249",
          emoji: "🇸🇩",
          code: "SD",
        },
        {
          name: "Suriname",
          dial_code: "+597",
          emoji: "🇸🇷",
          code: "SR",
        },
        {
          name: "Svalbard and Jan Mayen",
          dial_code: "+47",
          emoji: "🇸🇯",
          code: "SJ",
        },
        {
          name: "Swaziland",
          dial_code: "+268",
          emoji: "🇸🇿",
          code: "SZ",
        },
        {
          name: "Sweden",
          dial_code: "+46",
          emoji: "🇸🇪",
          code: "SE",
        },
        {
          name: "Switzerland",
          dial_code: "+41",
          emoji: "🇨🇭",
          code: "CH",
        },
        {
          name: "Syrian Arab Republic",
          dial_code: "+963",
          emoji: "🇸🇾",
          code: "SY",
        },
        {
          name: "Taiwan",
          dial_code: "+886",
          emoji: "🇹🇼",
          code: "TW",
        },
        {
          name: "Tajikistan",
          dial_code: "+992",
          emoji: "🇹🇯",
          code: "TJ",
        },
        {
          name: "Tanzania, United Republic of Tanzania",
          dial_code: "+255",
          emoji: "🇹🇿",
          code: "TZ",
        },
        {
          name: "Thailand",
          dial_code: "+66",
          emoji: "🇹🇭",
          code: "TH",
        },
        {
          name: "Timor-Leste",
          dial_code: "+670",
          emoji: "🇹🇱",
          code: "TL",
        },
        {
          name: "Togo",
          dial_code: "+228",
          emoji: "🇹🇬",
          code: "TG",
        },
        {
          name: "Tokelau",
          dial_code: "+690",
          emoji: "🇹🇰",
          code: "TK",
        },
        {
          name: "Tonga",
          dial_code: "+676",
          emoji: "🇹🇴",
          code: "TO",
        },
        {
          name: "Trinidad and Tobago",
          dial_code: "+1868",
          emoji: "🇹🇹",
          code: "TT",
        },
        {
          name: "Tunisia",
          dial_code: "+216",
          emoji: "🇹🇳",
          code: "TN",
        },
        {
          name: "Turkey",
          dial_code: "+90",
          emoji: "🇹🇷",
          code: "TR",
        },
        {
          name: "Turkmenistan",
          dial_code: "+993",
          emoji: "🇹🇲",
          code: "TM",
        },
        {
          name: "Turks and Caicos Islands",
          dial_code: "+1649",
          emoji: "🇹🇨",
          code: "TC",
        },
        {
          name: "Tuvalu",
          dial_code: "+688",
          emoji: "🇹🇻",
          code: "TV",
        },
        {
          name: "Uganda",
          dial_code: "+256",
          emoji: "🇺🇬",
          code: "UG",
        },
        {
          name: "Ukraine",
          dial_code: "+380",
          emoji: "🇺🇦",
          code: "UA",
        },
        {
          name: "United Arab Emirates",
          dial_code: "+971",
          emoji: "🇦🇪",
          code: "AE",
        },
        {
          name: "United Kingdom",
          dial_code: "+44",
          emoji: "🇬🇧",
          code: "GB",
        },
        {
          name: "United States",
          dial_code: "+1",
          emoji: "🇺🇸",
          code: "US",
        },
        {
          name: "Uruguay",
          dial_code: "+598",
          emoji: "🇺🇾",
          code: "UY",
        },
        {
          name: "Uzbekistan",
          dial_code: "+998",
          emoji: "🇺🇿",
          code: "UZ",
        },
        {
          name: "Vanuatu",
          dial_code: "+678",
          emoji: "🇻🇺",
          code: "VU",
        },
        {
          name: "Venezuela, Bolivarian Republic of Venezuela",
          dial_code: "+58",
          emoji: "🇻🇪",
          code: "VE",
        },
        {
          name: "Vietnam",
          dial_code: "+84",
          emoji: "🇻🇳",
          code: "VN",
        },
        {
          name: "Virgin Islands, British",
          dial_code: "+1284",
          emoji: "🇻🇬",
          code: "VG",
        },
        {
          name: "Virgin Islands, U.S.",
          dial_code: "+1340",
          emoji: "🇻🇮",
          code: "VI",
        },
        {
          name: "Wallis and Futuna",
          dial_code: "+681",
          emoji: "🇼🇫",
          code: "WF",
        },
        {
          name: "Yemen",
          dial_code: "+967",
          emoji: "🇾🇪",
          code: "YE",
        },
        {
          name: "Zambia",
          dial_code: "+260",
          emoji: "🇿🇲",
          code: "ZM",
        },
        {
          name: "Zimbabwe",
          dial_code: "+263",
          emoji: "🇿🇼",
          code: "ZW",
        },
      ],
    };
  },
  computed: {
    referred() {
      return this.$route.params.referralCode ? true : false;
    },
  },
  methods: {
    sendCode() {
      // Do something with the phone number
      if (this.$refs.signUpForm.validate()) {
        this.loading = true;
        this.$axios
          .get("https://api.axtoken.net/api/v2/username-check", {
            params: {
              username: this.email,
            },
          })
          .then((response) => {
            if (response.data.message == "exist") {
              this.$swal({
                title: "Error!",
                text: "Email already exist",
                icon: "error",
                confirmButtonText: "OK",
              });
              this.errorMessages.email = "Email already exist";
              this.loading = false;
            } else {
              this.$axios
                .get("https://api.axtoken.net/api/v2/send-code", {
                  params: {
                    email: this.email,
                  },
                })
                .then((response) => {
                  if (response.status === 200) {
                    this.step = 2;
                  } else {
                    this.$swal({
                      title: "Error!",
                      text: "Email not found",
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                  }
                  this.loading = false;
                });
            }
          });
      }
    },
    register() {
      if (this.$refs.signUpForm2.validate()) {
        this.loading = true;
        this.$axios
          .post("https://api.axtoken.net/api/v2/register", {
            code: this.verification_code,
            username: this.email,
            mobile_number: this.selectedCountryCode + this.phoneNumber,
            referral: this.referral_code,
            password: this.password,
            password_confirmation: this.password,
            transaction_password: this.password,
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.message == "CODE_DOES_NOT_MATCH")
                this.$swal({
                  title: "Error!",
                  text: "Verification code does not match",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              else if (response.data.message == "USER_ALREADY_EXIST")
                this.$swal({
                  title: "Error!",
                  text: "User already exist",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              else if (response.data.message == "SUCCESS") {
                this.login();
              }
            } else {
              this.$swal({
                title: "Error!",
                text: "Signup Failed",
                icon: "error",
                confirmButtonText: "OK",
              });
            }
            this.loading = false;
          });
      }
    },
    login() {
      this.$axios
        .post("https://api.axtoken.net/api/v2/login", {
          username: this.email,
          password: this.password,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("setToken", response.data.token);
            this.$store.commit("setUser", response.data.user);
            this.$store.commit("setClient", response.data.client);
            this.$store.dispatch("setWallet");
            this.$store.dispatch("setFutureWallet");
            this.$store.dispatch("setMarketCoins");
            //Set 1.5 seconds timeout to redirect to dashboard
            setTimeout(() => {
              this.$swal({
                toast: true,
                position: "top-end",
                title: "Login Success!",
                icon: "success",
                showConfirmButton: false,
                timer: 3000, // milliseconds
              });
              this.$router.push("/wallet");
              this.loading = false;
            }, 1500);
          } else {
            this.$swal({
              title: "Error!",
              text: "Login Failed",
              icon: "error",
              confirmButtonText: "OK",
            });
            this.loading = false;
          }
        });
    },
    goTo(path) {
      this.$router.push(path);
    },
  },
  mounted() {
    console.log(this.$route.params.referralCode);
    if (this.$route.params.referralCode) {
      this.referral = this.$route.params.referralCode;
    }
  },
};
</script>
<style>
/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
.signin-container {
  height: 90vh;
  /* background-image: url("../assets/images/bg2.jpg") !important; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background: linear-gradient(to bottom right, #13617c, #2e2e2e);
}
img {
  max-height: 300px; /* 50% of 280px */
  max-width: 300px; /* 50% of 280px */
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
