<template>
  <v-row>
    <v-col class="text-center">
      <h1 class="font-weight-bold">Trade on the go. Anywhere, anytime.</h1>
      <p class="grey--text darken-2">
        Stay in the know with our app and desktop client.
      </p>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-content-center align-items-start"
        >
          <img id="phones" src="@/assets/images/download.png" />
          <div class="mt-6">
            <h2 class="font-weight-bold mb-8">DOWNLOAD THE APP</h2>
            <a
              href="https://api.axtoken.net/api/v1/download-apk"
              target="_blank"
            >
              <img
                src="@/assets/images/play-store4.png"
                alt="Get it on Google Play"
                height="150"
              />
            </a>
            <span class="ml-4 mr-4 grey--text display-6">OR</span>
            <a
              href="https://api.axtoken.net/api/v1/download-ios"
              target="_blank"
            >
              <img
                src="@/assets/images/app-store4.png"
                alt="Download on the App Store"
                height="150"
              />
            </a>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: true,
  }),
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  computed: {
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    filteredCoins() {
      return this.marketCoins.filter((coin) => coin.type === "MAIN");
    },
  },
};
</script>
<style>
#phones {
  max-height: 600px; /* 50% of 280px */
  max-width: 600px; /* 50% of 280px */
  object-fit: contain;
}
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
</style>
