<template>
  <v-card class="portfolio-card">
    <v-card-text>
      <v-row justify="space-between">
        <v-col>
          <h4 class="font-weight-bold mb-0 white--text">Balances</h4>
        </v-col>
      </v-row>
      <div v-if="!loading">
        <v-row align="center" class="ma-0">
          <v-col cols="3">
            <p class="font-weight-bold white--text text--lighten-1 mb-0">
              Coin
            </p>
          </v-col>
          <v-col cols="3" class="text-start">
            <p class="font-weight-bold white--text text--lighten-1 mb-0">
              Total
            </p>
          </v-col>
          <v-col cols="3" class="text-start">
            <p class="font-weight-bold white--text text--lighten-1 mb-0">
              Available
            </p>
          </v-col>
          <v-col cols="3" class="text-end">
            <p class="font-weight-bold white--text text--lighten-1 mb-0">
              Value
            </p>
          </v-col>
        </v-row>
        <v-row
          v-for="(coin, index) in coins"
          :key="index"
          align="center"
          class="ma-0"
        >
          <v-col cols="3">
            <div class="d-flex justify-content-start align-items-center">
              <v-avatar size="25">
                <img :src="coin.image" :alt="name" />
              </v-avatar>
              <strong class="ml-3 mr-2">{{ coin.name }}</strong>
            </div>
          </v-col>
          <v-col cols="3" class="text-start">
            <p class="font-weight-bold white--text text--lighten-1 mb-0">
              {{ coin.coin_value | commaFormat }}
            </p>
          </v-col>
          <v-col cols="3" class="text-start">
            <p class="font-weight-bold white--text text--lighten-1 mb-0">0</p>
          </v-col>
          <v-col cols="3" class="text-end">
            <p class="font-weight-bold greenPrimary--text text--lighten-1 mb-0">
              {{ coin.usdt_value | commaFormat }} USDT
            </p>
          </v-col>
        </v-row>
      </div>
      <v-row v-else>
        <v-skeleton-loader
          type="list-item-three-line"
          class="mx-auto"
          :loading="loading"
        ></v-skeleton-loader>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    coins: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style>
.portfolio-card {
  margin-top: 5px !important;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>
