import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

export default new Vuex.Store({
    state: {
        token: null,
        user: null,
        client: null,
        marketCoins: null,
        wallet: null,
        futureWallet: null,
        walletOverview: null,
        walletPnl: null,
        selectedCoin: "BTC",
        delegateType: "BUY",
        transferType: "FUTURE",
        futureDelegateType: "BUY MORE",
        walletView: "tab-1",
    },
    getters: {
        user: (state) => state.user,
        token: (state) => state.token,
        client: (state) => state.client,
        check: (state) => state.user !== null,
        marketCoins: (state) => state.marketCoins,
        orders: (state) => state.orders,
        isAuthenticated: (state) => {
            return state.token !== null;
        },
        wallet: (state) => state.wallet,
        selectedCoin: (state) => state.selectedCoin,
        futureWallet: (state) => state.futureWallet,
        walletOverview: (state) => state.walletOverview,
        walletPnl: (state) => state.walletPnl,
        delegateType: (state) => state.delegateType,
        futureDelegateType: (state) => state.futureDelegateType,
        transferType: (state) => state.transferType,
        walletView: (state) => state.walletView,
    },
    mutations: {
        setToken: (state, token) => {
            state.token = token;
        },
        setUser: (state, user) => {
            state.user = user;
        },
        setClient: (state, client) => {
            state.client = client;
        },
        setMarketCoins: (state, marketCoins) => {
            state.marketCoins = marketCoins;
        },
        setOrders: (state, orders) => {
            state.orders = orders;
        },
        setWallet: (state, wallet) => {
            state.wallet = wallet;
        },
        setFutureWallet: (state, futureWallet) => {
            state.futureWallet = futureWallet;
        },
        setWalletOverview: (state, walletOverview) => {
            state.walletOverview = walletOverview;
        },
        setWalletPnl: (state, walletPnl) => {
            state.walletPnl = walletPnl;
        },
        setDelegateType: (state, delegateType) => {
            state.delegateType = delegateType;
        },
        setFuturesDelegateType: (state, futureDelegateType) => {
            state.futureDelegateType = futureDelegateType;
        },
        setWalletView: (state, walletView) => {
            state.walletView = walletView;
        },
        setTransferType: (state, transferType) => {
            state.transferType = transferType;
        },
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.marketCoins = [];
            state.orders = [];
            state.client = null;
            state.wallet = null;
            state.futureWallet = null;
        },
    },
    actions: {
        setToken: ({ commit }, token) => {
            commit("setToken", token);
        },
        setUser: ({ commit }, user) => {
            commit("setUser", user);
        },
        setClient: ({ commit }, client) => {
            commit("setClient", client);
        },
        setMarketCoins: ({ commit }) => {
            axios
                .get("https://api.axtoken.net/api/v2/market-all-pairs")
                .then((response) => {
                    commit("setMarketCoins", response.data.coinpair);
                });
        },
        setOrders: (state, { commit }) => {
            console.log('wew');
            axios
                .get(`https://api.axtoken.net/api/v2/recent-trades`, {
                    params: {
                        name: state.selectedCoin + "USDT",
                    },
                })
                .then((response) => {
                    commit("setOrders", response.data);
                });
        },
        setWallet: ({ commit }) => {
            axios
                .get("https://api.axtoken.net/api/v1/wallets/spot")
                .then((response) => {
                    commit("setWallet", response.data);
                });
        },
        setFutureWallet: ({ commit }) => {
            axios
                .get("https://api.axtoken.net/api/v2/future-wallet")
                .then((response) => {
                    commit("setFutureWallet", response.data);
                });
        },
        fetchClientUser: ({ commit, state }) => {
            axios
                .get("https://api.axtoken.net/api/v1/clients", {
                    params: {
                        client_id: state.client.id,
                    },
                })
                .then((response) => {
                    let client = {
                        ...response.data.clients,
                        verified:
                            response.data.verified == "FALSE" ? false : true,
                    };
                    commit("setClient", client);
                    commit("setUser", client.user);
                });
        },
        setWalletOverview: ({ commit }) => {
            axios
                .get("https://api.axtoken.net/api/v1/overview")
                .then((response) => {
                    let overview = response.data;
                    let wallets = [
                        { name: "Spot", value: overview.spotTotal },
                        { name: "Earn", value: overview.earnTotal },
                        { name: "Future", value: overview.futureTotal },
                    ];
                    commit("setWalletOverview", wallets);
                    commit("setWalletPnl", overview.pnl);
                });
        },

        setWallets: ({ dispatch }) => {
            dispatch("setWallet");
            dispatch("setFutureWallet");
            dispatch("setWalletOverview");
        },

        logout: ({ commit }) => {
            commit("logout");
        },
    },
    modules: {},
    plugins: [vuexLocal.plugin],
});
