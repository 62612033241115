<template>
  <v-row justify="center">
    <v-col cols="12" lg="9">
      <v-row justify="center">
        <v-col cols="9">
          <v-toolbar class="transparent elevation-0">
            <h4 class="font-weight-bold mb-0" @click="marketCoinsDialog = true">
              {{ selectedCoin }}/USDT
            </h4>
            <span
              :class="
                selectedMarketCoin.percent < 0
                  ? 'ml-3 redPrimary--text'
                  : 'ml-3 greenPrimary--text'
              "
              >{{ selectedMarketCoin.percent }}%</span
            >
            <v-col
              cols="2"
              :class="
                selectedMarketCoin.percent < 0
                  ? 'ml-3 redPrimary--text ml-2 text-center'
                  : 'ml-3 greenPrimary--text ml-2 text-center'
              "
            >
              <h5 class="font-weight-bold">
                {{ orders.price ? orders.price : 0 }}
              </h5>
            </v-col>
            <v-col cols="1" class="text-left">
              <p class="volume-caption mt-0">24h High</p>
              <p class="volume-caption mt-0 white--text">
                {{ latestDetails.high | commaWithDecimal }}
              </p>
            </v-col>
            <v-col cols="1" class="text-left">
              <p class="volume-caption mt-0">24h Vol(BTC)</p>
              <p class="volume-caption mt-0 white--text">
                {{ latestDetails.volume | commaWithDecimal }}
              </p>
            </v-col>
            <v-col cols="1" class="text-left">
              <p class="volume-caption mt-0">24h Low</p>
              <p class="volume-caption mt-0 white--text">
                {{ latestDetails.low | commaWithDecimal }}
              </p>
            </v-col>
            <v-col cols="2" class="text-left">
              <p class="volume-caption mt-0">24h Vol(USDT)</p>
              <p class="volume-caption mt-0 white--text">
                {{ latestDetails.volume | commaWithDecimal }}
              </p>
            </v-col>
          </v-toolbar>
          <v-row>
            <v-col cols="3">
              <main-trades-orders :selectedCoin="selectedCoin" />
            </v-col>
            <v-col cols="9">
              <v-container>
                <v-row justify="center">
                  <v-col cols="12">
                    <div class="text-start">
                      <v-btn-toggle
                        v-model="timeFrame"
                        color="primary"
                        mandatory
                        group
                        dense
                      >
                        <v-btn x-small class="mx-1" value="1min"> 1m </v-btn>
                        <v-btn x-small class="mx-1" value="5min"> 5m </v-btn>
                        <v-btn x-small class="mx-1" value="1hr"> 1h </v-btn>
                        <v-btn x-small class="mx-1" value="4hr"> 4h </v-btn>
                        <!-- <v-btn x-small class="mx-1" value="24hr"> 1d </v-btn> -->
                      </v-btn-toggle>
                    </div>
                    <main-trades-chart :dataRaw="dataRaw"></main-trades-chart>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-form ref="tradeForm" lazy-loading>
                      <v-row>
                        <v-col cols="12" class="mt-0 py-1">
                          <v-select
                            :items="['x10', 'x15', 'x20']"
                            v-model="leverage"
                            solo
                            class="centered-input"
                            height="28px"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" class="mb-0 pb-1">
                          <v-btn-toggle
                            v-model="delegateType"
                            color="transparent"
                            mandatory
                            class="full-width-toggle"
                            @change="setAmountPercentage"
                          >
                            <v-btn
                              v-for="(type, index) in delegateTypes"
                              :key="index"
                              :value="type"
                              small
                              :class="
                                delegateType === type
                                  ? 'white--text'
                                  : 'grey--text'
                              "
                              :color="
                                delegateType === type
                                  ? delegateColor
                                  : 'greyPrimary'
                              "
                            >
                              {{ type }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-col cols="12" class="mt-0 py-1">
                          <v-text-field
                            disabled
                            label="Market Price"
                            solo
                            height="28px"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mb-0 pb-1">
                          <v-btn-toggle
                            v-model="priceType"
                            color="transparent"
                            mandatory
                            class="full-width-toggle"
                            @change="setAmountPercentage"
                          >
                            <v-btn
                              v-for="(type, index) in priceTypes"
                              :key="index"
                              :value="type"
                              small
                              :class="
                                priceType === type
                                  ? 'white--text'
                                  : 'grey--text'
                              "
                              color="greyPrimary"
                            >
                              {{ type == "COIN" ? selectedCoin : "USDT" }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-col cols="12" class="mb-0 pb-1">
                          <v-rating
                            hover
                            length="4"
                            v-model="amountPercentage"
                            class="rating-flex"
                            @input="setAmountPercentage"
                          >
                            <template v-slot:item="props">
                              <div class="icon-with-label">
                                <v-icon
                                  size="25"
                                  :color="
                                    props.isFilled
                                      ? 'greenPrimary'
                                      : 'grey lighten-1'
                                  "
                                  class="py-0"
                                  @click="props.click"
                                >
                                  {{
                                    props.isFilled
                                      ? "mdi-rectangle"
                                      : "mdi-rectangle-outline"
                                  }}
                                </v-icon>
                                <span class="rating-label">{{
                                  ratingText(props.index)
                                }}</span>
                              </div>
                            </template>
                          </v-rating>
                        </v-col>
                        <label
                          :class="
                            balanceToDisplay <= 0
                              ? 'caption error--text font-weight-thin'
                              : 'caption success--text font-weight-thin'
                          "
                          >Available:
                          {{ balanceToDisplay | commaFormat }}</label
                        >
                        <v-col cols="12" class="mt-0 py-1">
                          <v-text-field
                            v-model="amount"
                            prepend-inner-icon="mdi-minus"
                            append-icon="mdi-plus"
                            :label="
                              priceType === 'COIN' ? selectedCoin : 'USDT'
                            "
                            height="28px"
                            :rules="amountRules()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mt-0 py-1">
                          <v-btn
                            block
                            medium
                            :color="delegateColor"
                            @click="trade"
                          >
                            <span class="text-capitalize">{{
                              delegateType === "BUY MORE"
                                ? "BUY MORE"
                                : "SELL EMPTY"
                            }}</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
                <v-row class="mt-10">
                  <v-col>
                    <v-tabs color="secondary" background-color="#00000000">
                      <v-tab>Order Book</v-tab>
                      <v-tab-item>
                        <!-- <div v-if="!loading"> -->
                        <v-row v-if="margins.length > 0" align="center">
                          <v-col>
                            <v-card
                              v-for="(margin, index) in margins"
                              :key="index"
                              class="my-2"
                            >
                              <v-toolbar class="transparent elevation-0">
                                <v-toolbar-title class="font-weight-bold">
                                  {{ margin.coin_pair.pair_name }}
                                </v-toolbar-title>
                                <span class="ml-4">{{ margin.leverage }}x</span>
                                <v-spacer></v-spacer>
                              </v-toolbar>
                              <v-card-text class="pt-2">
                                <v-row>
                                  <v-col cols="6" class="py-1">
                                    <span class="margin-title">
                                      Unrealized PNL (USDT)
                                    </span>
                                    <h6 :class="textColor(margin.income)">
                                      {{ margin.income }}
                                    </h6>
                                  </v-col>
                                  <v-col cols="6" class="text-end py-1">
                                    <span class="margin-title">ROE</span>
                                    <h6
                                      :class="
                                        textColor(margin.rate_of_return * 10)
                                      "
                                    >
                                      {{ margin.rate_of_return * 10 }}
                                    </h6>
                                  </v-col>
                                  <v-col cols="4" class="py-1">
                                    <span class="margin-title">Size(USDT)</span>
                                    <h6 class="white--text margin-value mb-0">
                                      {{ margin.total_asset }}
                                    </h6>
                                  </v-col>
                                  <v-col cols="4" class="py-1">
                                    <span class="margin-title"
                                      >Margin(USDT)</span
                                    >
                                    <h6 class="white--text margin-value mb-0">
                                      {{ margin.margin }}
                                    </h6>
                                  </v-col>
                                  <v-col cols="4" class="text-end py-1">
                                    <span class="margin-title">Risk</span>
                                    <h6 class="white--text margin-value mb-0">
                                      --
                                    </h6>
                                  </v-col>
                                  <v-col cols="4" class="py-1">
                                    <span class="margin-title"
                                      >Entry Price</span
                                    >
                                    <h6 class="white--text margin-value mb-0">
                                      {{ margin.opening_price }}
                                    </h6>
                                  </v-col>
                                  <v-col cols="4" class="py-1">
                                    <span class="margin-title">Mark Price</span>
                                    <h6 class="white--text margin-value mb-0">
                                      --
                                    </h6>
                                  </v-col>
                                  <v-col cols="4" class="text-end py-1">
                                    <span class="margin-title">Liq. Price</span>
                                    <h6 class="white--text margin-value mb-0">
                                      {{ margin.estimated_force_parity }}
                                    </h6>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  small
                                  color="primary"
                                  @click="closeMargin(margin.id)"
                                  >close position</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col class="text-center">
                            <v-img
                              src="../../assets/images/no-data.svg"
                              max-width="200"
                              max-height="200"
                            ></v-img>
                            <h5 class="mt-5">No Margins yet</h5>
                          </v-col>
                        </v-row>
                        <!-- </div>
                        <v-row v-else>
                          <v-col class="text-center">
                            <v-progress-circular
                              indeterminate
                              :size="50"
                              :width="7"
                              color="secondary"
                              class="mt-5"
                            ></v-progress-circular
                          ></v-col>
                        </v-row> -->
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-list dense>
            <v-list-item-group v-model="navSelectedCoin" mandatory>
              <div v-for="coin in marketCoins" :key="coin.name">
                <v-list-item @click="selectCoin(coin)">
                  <v-list-item-content>
                    <v-list-item-title>{{ coin.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ coin.amount }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content class="text-right">
                    <v-list-item-title
                      :class="
                        coin.percent < 0
                          ? 'text-right redPrimary--text'
                          : 'text-right greenPrimary--text'
                      "
                      >{{ coin.percent }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="ma-0"></v-divider>
              </div>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="confirmDialog" persistent width="400px">
      <v-card width="400px">
        <v-card-title>
          <v-toolbar class="elevation-0 transparent">
            <v-spacer></v-spacer>
            <v-toolbar-title>Confirmation</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="confirmationForm" lazy-loading>
            <v-row>
              <v-col cols="12">
                <label>Transaction Password</label>
                <v-text-field
                  v-model="password"
                  :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="visible = !visible"
                  :rules="passwordRules()"
                  :type="visible ? 'text' : 'password'"
                  :error-messages="errorMessage.password"
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" large text @click="confirmDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="px-4"
            large
            rounded
            @click="trade(), (loader = 'loading')"
            :loading="loading"
            :disabled="loading"
          >
            Confirm
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "futures",
  data: () => ({
    navSelectedCoin: 0,
    loading: false,
    visible: false,
    marketCoinsDialog: false,
    tradeType: 2,
    delegateTypes: ["BUY MORE", "SELL EMPTY"],
    priceTypes: ["USDT", "COIN"],
    leverage: "x10",
    priceType: "USDT",
    amountPercentage: 0,
    amount: null,
    password: null,
    margins: [],
    minimum: {
      minimum_sell: 0,
      account_balance_limit_spot: 0,
    },
    errorMessage: {
      password: "",
    },
    latestDetails: {
      amount: 0,
      percent: 0,
    },
    previousPath: "",
    confirmDialog: false,
  }),

  computed: {
    delegateType: {
      get() {
        return this.$store.state.futureDelegateType;
      },
      set(value) {
        this.$store.state.futureDelegateType = value;
      },
    },
    selectedCoin: {
      get() {
        return this.$store.state.selectedCoin;
      },
      set(value) {
        this.$store.state.selectedCoin = value;
      },
    },
    selectedMarketCoin() {
      return this.$store.getters.marketCoins.find(
        (coin) => coin.name == this.selectedCoin
      );
    },
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    coins() {
      //Get name only
      return this.$store.getters.marketCoins.map((coin) => coin.name);
    },
    numberOfOrder() {
      if (this.priceType === "USDT") {
        return this.amount / this.valuePerUnit;
      } else {
        return this.amount;
      }
    },
    total() {
      if (this.priceType === "USDT") {
        return this.amount;
      } else {
        return this.amount * this.valuePerUnit;
      }
    },
    valuePerUnit() {
      return this.selectedMarketCoin.value;
    },
    balanceInUSDT() {
      let wallets = this.$store.getters.futureWallet.wallet;
      return wallets.find((item) => item.name === this.selectedCoin).usdt_value;
    },
    balanceInCoin() {
      let wallets = this.$store.getters.futureWallet.wallet;
      let balance = wallets.find(
        (item) => item.name === this.selectedCoin
      ).coin_value;

      return balance;
    },
    balanceToDisplay() {
      return this.priceType == "COIN" ? this.balanceInCoin : this.balanceInUSDT;
    },
    minimumSell() {
      if (this.minimum.minimum_sell == "0") return 0;
      else {
        if (this.priceType == "COIN") return this.minimum.minimum_sell;
        else return this.minimum.minimum_sell * this.valuePerUnit;
      }
    },
    minimumBuy() {
      if (this.minimum.account_balance_limit_spot == "0") return 0;
      else {
        if (this.priceType == "COIN")
          return this.minimum.account_balance_limit_spot;
        else return this.minimum.account_balance_limit_spot * this.valuePerUnit;
      }
    },
    delegateColor() {
      return this.delegateType == "SELL EMPTY" ? "redPrimary" : "greenPrimary";
    },
    orders: function () {
      return this.$store.state.orders;
    },
  },
  methods: {
    selectCoin(coin) {
      this.$router.push(`/trades/futures/${coin.name}`);
      this.navSelectedCoin = this.marketCoins.indexOf(coin) + 1;
      this.selectedCoin = coin.name;
      this.fetchMinimum(coin.name);
      // this.$route.params.coin = coin.name;
      // this.marketCoinsDialog = false;
    },
    fetchMinimum(coin) {
      this.$axios
        .get(`https://api.axtoken.net/api/v1/getOrder?symbol=${coin}`)
        .then((response) => {
          this.minimum = response.data.price;
        });
    },
    fetchMargins() {
      this.loading = true;
      this.$axios
        .get("https://api.axtoken.net/api/v2/client-margin")
        .then((response) => {
          this.margins = response.data.margin;
          this.loading = false;
        });
    },
    amountRules() {
      return this.delegateType === "SELL EMPTY"
        ? [
            (v) => !!v || "Amount is required",
            (v) =>
              v >= this.minimumSell ||
              `Amount must be greater than minimum sell amount (${this.minimumSell})`,
            (v) =>
              v <= this.balanceToDisplay ||
              `Insufficient balance (${this.balanceToDisplay})`,
          ]
        : [
            (v) => !!v || "Amount is required",
            (v) =>
              v >= this.minimumBuy ||
              `Amount must be greater than minimum buy amount (${this.minimumBuy})`,
            (v) =>
              v <= this.balanceToDisplay ||
              `Insufficient balance (${this.balanceToDisplay})`,
          ];
    },
    passwordRules() {
      return [(v) => !!v || "Password is required"];
    },
    trade() {
      if (this.$refs.tradeForm.validate()) {
        this.loading = true;
        this.$axios
          .post("https://api.axtoken.net/api/v2/buy-more", {
            name: this.selectedCoin,
            delegate_type: this.delegateType //BUY MORE, SELL EMPTY
              .replace(" ", "_"),
            margin: this.total,
            leverage: this.leverage.replace("x", ""),
            opening_price: this.valuePerUnit,
          })
          .then((response) => {
            this.loading = false;
            if (response.data.message == "success") {
              this.$swal({
                title: "Success",
                text: "Order created successfully",
                icon: "success",
                timer: 3000,
                buttons: false,
              });
              this.$store.dispatch("setFutureWallet");
            } else if (response.data.message === "wrong_transaction_password") {
              this.$swal({
                title: "Error",
                text: "Wrong transaction password",
                icon: "error",
                timer: 3000,
                buttons: false,
              });
              this.errorMessage.password = "Wrong transaction password";
            } else {
              this.$swal({
                title: "Error",
                text: "Something went wrong",
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$swal({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 3000,
              buttons: false,
            });
          });
      }
    },
    ratingText(index) {
      return index == 0
        ? "25%"
        : index == 1
        ? "50%"
        : index == 2
        ? "75%"
        : "100%";
    },
    closeMargin(id) {
      this.$axios
        .get("https://api.axtoken.net/api/v1/futures/close", {
          params: { future_id: id },
        })
        .then((response) => {
          if (response.data.message == "closeRequest") {
            this.$swal({
              title: "Success",
              text: "Congratulations, you can now request to the Customer Service to allow your position to be closed! Please proceed to Customer Service and ask to allow your position to be closed",
              icon: "success",
              timer: null,
              buttons: false,
            });
            this.$store.dispatch("setFutureWallet");
            this.$router.push("/home");
          } else if (response.data.message === "closePositionError") {
            this.$swal({
              html: `Remaining days: ${response.data.daysLeft} <br/>
                                If your income amount is greater than or equal to ${response.data.futureTarget},
                                or total assets is less than equal to ${response.data.futureFundsToAdd}, can be closed in advance directly.`,
              icon: "error",
              showCancelButton: false,
            });
            this.errorMessage.password = "Wrong transaction password";
          } else {
            this.$swal({
              title: "Error",
              text: "Something went wrong",
              icon: "error",
              timer: 3000,
              buttons: false,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            timer: 3000,
            buttons: false,
          });
        });
    },
    closeAllMargins() {
      this.margins.forEach((margin) => {
        this.closeMargin(margin.id);
      });
    },
    setAmountPercentage() {
      this.amount = this.balanceToDisplay * this.amountPercentage * 0.25;
    },
    textColor(amount) {
      //Return the color based on the percent value if positive or negative
      return amount >= 0
        ? "greenPrimary--text font-weight-bold mb-0"
        : "redPrimary--text font-weight-bold mb-0";
    },
    async getTradesChart() {
      try {
        const response = await this.$axios.get(
          `https://api.axtoken.net/api/v2/chart/chart-series?symbol=${this.selectedCoin}&timeframe=${this.timeFrame}`
        );
        //Save marketCoins to vuex
        // this.$store.commit("setMarketCoins", response.data.coinpair);
        this.dataRaw = response.data;
        //Get current price
        // this.coinDetails.amount = this.dataRaw.latest_time.close;
        this.latestDetails = this.dataRaw.latest_time;
        //Get percent change
        // this.coinDetails.percent = this.dataRaw.latest_time.percent;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    async fetchOrders() {
      try {
        this.$axios
          .get(`https://api.axtoken.net/api/v2/recent-trades`, {
            params: {
              name: this.selectedCoin + "USDT",
            },
          })
          .then((response) => {
            //Save Orders to vuex
            this.$store.commit("setOrders", response.data);
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  },

  mounted() {
    this.selectedCoin = this.$route.params.coin;
    if (this.$store.getters.isAuthenticated) {
      this.fetchOrders();
      this.$store.dispatch("setWallets");
      this.fetchMinimum(this.selectedCoin);
    }
    this.getTradesChart();
    this.fetchMargins();
    //Add interval
    this.interval = setInterval(() => {
      this.getTradesChart();
      this.fetchMargins();
    }, 5000);
  },
  //Remove/Clear interval and data when component is destroyed
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
<style>
.toolbar-buttons {
  background-color: transparent !important;
}
.trade-container {
  width: 100vw !important;
  border-radius: 20px !important;
  background-color: #1e1e1e !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.full-width-toggle {
  display: flex;
  width: 100%;
}

.full-width-toggle .v-btn {
  flex-grow: 1;
  flex-basis: 0;
}
.v-text-field.v-text-field--solo .v-input__control {
  height: 28px !important;
}
.v-text-field.v-text-field--solo .v-input__control .v-input__slot {
  height: 28px !important;
}

.margin-title {
  font-size: 12px;
}
.margin-value {
  font-size: 14px;
}

.rating-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-with-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-label {
  margin-top: 0px;
  font-size: 9px;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
