<template>
  <v-row justify="center">
    <v-col cols="11" xl="9">
      <v-row justify="center">
        <v-col cols="9">
          <v-toolbar class="transparent elevation-0">
            <h4 class="font-weight-bold mb-0" @click="marketCoinsDialog = true">
              {{ selectedCoin }}/USDT
            </h4>
            <span
              :class="
                selectedMarketCoin.percent < 0
                  ? 'ml-3 redPrimary--text'
                  : 'ml-3 greenPrimary--text'
              "
              >{{ selectedMarketCoin.percent }}%</span
            >
            <v-col
              cols="2"
              :class="
                selectedMarketCoin.percent < 0
                  ? 'ml-3 redPrimary--text ml-2 text-center'
                  : 'ml-3 greenPrimary--text ml-2 text-center'
              "
            >
              <h5 class="font-weight-bold">
                {{ orders.price ? orders.price : 0 }}
              </h5>
            </v-col>
            <v-col cols="1" class="text-left">
              <p class="volume-caption mt-0">24h High</p>
              <p class="volume-caption mt-0 white--text">
                {{ latestDetails.high | commaWithDecimal }}
              </p>
            </v-col>
            <v-col cols="1" class="text-left">
              <p class="volume-caption mt-0">24h Vol(BTC)</p>
              <p class="volume-caption mt-0 white--text">
                {{ latestDetails.volume | commaWithDecimal }}
              </p>
            </v-col>
            <v-col cols="1" class="text-left">
              <p class="volume-caption mt-0">24h Low</p>
              <p class="volume-caption mt-0 white--text">
                {{ latestDetails.low | commaWithDecimal }}
              </p>
            </v-col>
            <v-col cols="2" class="text-left">
              <p class="volume-caption mt-0">24h Vol(USDT)</p>
              <p class="volume-caption mt-0 white--text">
                {{ latestDetails.volume | commaWithDecimal }}
              </p>
            </v-col>
          </v-toolbar>
          <v-row>
            <v-col cols="3">
              <main-trades-orders :selectedCoin="selectedCoin" />
            </v-col>
            <v-col cols="9">
              <v-container>
                <v-row justify="center">
                  <v-col cols="12">
                    <div class="text-start">
                      <v-btn-toggle
                        v-model="timeFrame"
                        color="primary"
                        mandatory
                        group
                        dense
                      >
                        <v-btn x-small class="mx-1" value="1min"> 1m </v-btn>
                        <v-btn x-small class="mx-1" value="5min"> 5m </v-btn>
                        <v-btn x-small class="mx-1" value="1hr"> 1h </v-btn>
                        <v-btn x-small class="mx-1" value="4hr"> 4h </v-btn>
                        <!-- <v-btn x-small class="mx-1" value="24hr"> 1d </v-btn> -->
                      </v-btn-toggle>
                    </div>
                    <main-trades-chart :dataRaw="dataRaw"></main-trades-chart>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-form ref="tradeForm" lazy-loading>
                      <v-row>
                        <v-col cols="12" class="mb-0 pb-1">
                          <v-btn-toggle
                            v-model="delegateType"
                            color="transparent"
                            mandatory
                            class="full-width-toggle"
                            @change="setAmountPercentage"
                          >
                            <v-btn
                              v-for="(type, index) in delegateTypes"
                              :key="index"
                              :value="type"
                              small
                              :class="
                                delegateType === type
                                  ? 'white--text'
                                  : 'grey--text'
                              "
                              :color="
                                delegateType === type
                                  ? type === 'BUY'
                                    ? 'greenPrimary'
                                    : 'redPrimary'
                                  : 'greyPrimary'
                              "
                            >
                              {{ type }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-col cols="12" class="mt-0 py-1">
                          <v-select
                            :items="['MARKET', 'LIMIT']"
                            v-model="marketType"
                            solo
                            class="centered-input"
                            height="28px"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" class="mt-0 py-1">
                          <v-text-field
                            disabled
                            label="Market Price"
                            solo
                            height="28px"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="mb-0 pb-1">
                          <v-btn-toggle
                            v-model="priceType"
                            color="transparent"
                            mandatory
                            class="full-width-toggle"
                            @change="setAmountPercentage"
                          >
                            <v-btn
                              v-for="(type, index) in priceTypes"
                              :key="index"
                              :value="type"
                              small
                              :class="
                                priceType === type
                                  ? 'white--text'
                                  : 'grey--text'
                              "
                              color="greyPrimary"
                            >
                              {{ type }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <!-- V-If Start here-->
                        <div v-if="isAuthenticated">
                          <v-col cols="12" class="mb-0 pb-1">
                            <v-rating
                              hover
                              length="4"
                              v-model="amountPercentage"
                              class="rating-flex"
                              @input="setAmountPercentage"
                            >
                              <template v-slot:item="props">
                                <div class="icon-with-label">
                                  <v-icon
                                    size="25"
                                    :color="
                                      props.isFilled
                                        ? 'greenPrimary'
                                        : 'grey lighten-1'
                                    "
                                    class="py-0"
                                    @click="props.click"
                                  >
                                    {{
                                      props.isFilled
                                        ? "mdi-rectangle"
                                        : "mdi-rectangle-outline"
                                    }}
                                  </v-icon>
                                  <span class="rating-label">{{
                                    ratingText(props.index)
                                  }}</span>
                                </div>
                              </template>
                            </v-rating>
                          </v-col>
                          <label
                            :class="
                              balanceToDisplay <= 0
                                ? 'caption error--text font-weight-thin'
                                : 'caption success--text font-weight-thin'
                            "
                            >Available:
                            {{ balanceToDisplay | commaFormat }}</label
                          >
                          <v-col cols="12" class="mt-0 py-1">
                            <v-text-field
                              v-model="amount"
                              prepend-inner-icon="mdi-minus"
                              append-icon="mdi-plus"
                              :label="`(${selectedCoin})`"
                              height="28px"
                              :rules="amountRules()"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="mt-0 py-1">
                            <v-btn
                              block
                              medium
                              :color="
                                delegateType === 'BUY'
                                  ? 'greenPrimary'
                                  : 'redPrimary'
                              "
                              @click="confirm"
                            >
                              <span class="text-capitalize"
                                >{{ delegateType === "BUY" ? "BUY" : "SELL" }}
                                {{ selectedCoin }}</span
                              >
                            </v-btn>
                          </v-col>
                        </div>
                        <div v-else>
                          <v-btn
                            class="mt-4 white--text"
                            block
                            large
                            color="primary"
                            to="/signin"
                          >
                            <span class="text-capitalize">SIGN-IN</span>
                          </v-btn>
                        </div>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
                <v-row class="mt-10">
                  <v-col>
                    <v-tabs color="secondary" background-color="#00000000">
                      <v-tab>Order Book</v-tab>
                      <v-tab-item v-if="!loading">
                        <v-row class="mt-4">
                          <v-col cols="6">
                            <span class="list-title">Bid</span>
                            <v-row class="price-list">
                              <v-col cols="6" class="text-left">
                                <h1 class="subtitle-1">Price(USDT)</h1>
                                <span
                                  class="greenPrimary--text"
                                  v-for="(bid, index) in orders.bids"
                                  :key="index"
                                >
                                  {{ bid.unit_price }}</span
                                >
                              </v-col>
                              <v-col cols="6" class="text-right">
                                <h1 class="subtitle-1">
                                  Value({{ selectedCoin }})
                                </h1>
                                <span
                                  class="white--text"
                                  v-for="(bid, index) in orders.bids"
                                  :key="index"
                                >
                                  {{ bid.number_of_order }}</span
                                >
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="6">
                            <span class="list-title">Ask</span>
                            <v-row class="price-list">
                              <v-col cols="6" class="text-left">
                                <h1 class="subtitle-1">Price(USDT)</h1>
                                <span
                                  class="redPrimary--text"
                                  v-for="(ask, index) in orders.asks"
                                  :key="index"
                                >
                                  {{ ask.unit_price }}</span
                                >
                              </v-col>
                              <v-col cols="6" class="text-right">
                                <h1 class="subtitle-1">
                                  Value({{ selectedCoin }})
                                </h1>
                                <span
                                  class="white--text"
                                  v-for="(ask, index) in orders.asks"
                                  :key="index"
                                >
                                  {{ ask.number_of_order }}</span
                                >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-list dense>
            <v-list-item-group v-model="navSelectedCoin" mandatory>
              <div v-for="coin in marketCoins" :key="coin.name">
                <v-list-item @click="selectCoin(coin)">
                  <v-list-item-content>
                    <v-list-item-title>{{ coin.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ coin.amount }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content class="text-right">
                    <v-list-item-title
                      :class="
                        coin.percent < 0
                          ? 'text-right redPrimary--text'
                          : 'text-right greenPrimary--text'
                      "
                      >{{ coin.percent }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="ma-0"></v-divider>
              </div>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="confirmDialog" persistent width="400px">
      <v-card width="400px">
        <v-card-title>
          <v-toolbar class="elevation-0 transparent">
            <v-spacer></v-spacer>
            <v-toolbar-title>Confirmation</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="confirmationForm" lazy-loading>
            <v-row>
              <v-col cols="12">
                <label>Transaction Password</label>
                <v-text-field
                  v-model="password"
                  :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="visible = !visible"
                  :rules="passwordRules()"
                  :type="visible ? 'text' : 'password'"
                  :error-messages="errorMessage.password"
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" large text @click="confirmDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="px-4"
            large
            rounded
            @click="trade(), (loader = 'loading')"
            :loading="loading"
            :disabled="loading"
          >
            Confirm
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import store from "@/store";
export default {
  name: "trades",
  data: () => ({
    navSelectedCoin: 0,
    loading: false,
    visible: false,
    marketCoinsDialog: false,
    delegateTypes: ["BUY", "SELL"],
    marketType: "MARKET",
    priceTypes: ["AMOUNT", "TOTAL"],
    priceType: "AMOUNT",
    amountPercentage: 0,
    amount: null,
    password: null,
    minimum: {
      minimum_sell: 0,
      account_balance_limit_spot: 0,
    },
    errorMessage: {
      password: "",
    },
    dataRaw: {
      dates: [],
      series: [],
      current: {},
    },
    timeFrame: "1min",
    step: 1,
    coinDetails: {
      open: 0,
      close: 0,
      high: 0,
      low: 0,
      volume: 0,
    },
    latestDetails: {
      amount: 0,
      percent: 0,
    },
    previousPath: "",
    confirmDialog: false,
  }),

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    delegateType: {
      get() {
        return this.$store.state.delegateType;
      },
      set(value) {
        this.$store.state.delegateType = value;
      },
    },
    selectedCoin: {
      get() {
        return this.$store.state.selectedCoin;
      },
      set(value) {
        this.$store.state.selectedCoin = value;
      },
    },
    selectedMarketCoin() {
      return this.$store.getters.marketCoins.find(
        (coin) => coin.name == this.selectedCoin
      );
    },
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    coins() {
      //Get name only
      return this.$store.getters.marketCoins.map((coin) => coin.name);
    },
    numberOfOrder() {
      if (this.priceType === "AMOUNT") {
        return this.amount / this.valuePerUnit;
      } else {
        return this.amount;
      }
    },
    total() {
      if (this.priceType === "AMOUNT") {
        return this.amount;
      } else {
        return this.amount * this.valuePerUnit;
      }
    },
    valuePerUnit() {
      return this.selectedMarketCoin.value;
    },
    sellBalanceInUSDT() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === this.selectedCoin).usdt_value;
    },
    sellBalanceInCoin() {
      let wallets = this.$store.getters.wallet.wallet;
      let balance = wallets.find(
        (item) => item.name === this.selectedCoin
      ).coin_value;

      return balance;
    },
    buyBalanceInUSDT() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === "USDT").usdt_value;
    },
    buyBalanceInCoin() {
      let wallets = this.$store.getters.wallet.wallet;
      let balance =
        wallets.find((item) => item.name === "USDT").usdt_value /
        this.valuePerUnit;
      return balance;
    },
    balanceToDisplay() {
      if (this.delegateType == "SELL") {
        return this.priceType == "TOTAL"
          ? this.sellBalanceInCoin
          : this.sellBalanceInUSDT;
      } else {
        return this.priceType == "TOTAL"
          ? this.buyBalanceInCoin
          : this.buyBalanceInUSDT;
      }
      // return 0;
    },
    minimumSell() {
      if (this.minimum.minimum_sell == "0") return 0;
      else {
        if (this.priceType == "TOTAL") return this.minimum.minimum_sell;
        else return this.minimum.minimum_sell * this.valuePerUnit;
      }
    },
    minimumBuy() {
      if (this.minimum.account_balance_limit_spot == "0") return 0;
      else {
        if (this.priceType == "TOTAL")
          return this.minimum.account_balance_limit_spot;
        else return this.minimum.account_balance_limit_spot * this.valuePerUnit;
      }
    },
    orders: function () {
      return this.$store.state.orders;
    },
  },
  methods: {
    selectCoin(coin) {
      this.$router.push(`/trades/spot/${coin.name}`);
      this.navSelectedCoin = this.marketCoins.indexOf(coin) + 1;
      this.selectedCoin = coin.name;
      this.fetchMinimum(coin.name);
      // // this.$route.params.coin = coin.name;
      // this.marketCoinsDialog = false;
    },
    fetchMinimum(coin) {
      this.$axios
        .get(`https://api.axtoken.net/api/v1/getOrder?symbol=${coin}`)
        .then((response) => {
          this.minimum = response.data.price;
        });
    },
    amountRules() {
      return this.delegateType === "SELL"
        ? [
            (v) => !!v || "Amount is required",
            (v) =>
              v >= this.minimumSell ||
              `Amount must be greater than minimum sell amount (${this.minimumSell})`,
            (v) =>
              v <= this.balanceToDisplay ||
              `Insufficient balance (${this.balanceToDisplay})`,
          ]
        : [
            (v) => !!v || "Amount is required",
            (v) =>
              v >= this.minimumBuy ||
              `Amount must be greater than minimum buy amount (${this.minimumBuy})`,
            (v) =>
              v <= this.balanceToDisplay ||
              `Insufficient balance (${this.balanceToDisplay})`,
          ];
    },
    passwordRules() {
      return [(v) => !!v || "Password is required"];
    },
    confirm() {
      if (this.$refs.tradeForm.validate()) this.confirmDialog = true;
    },
    trade() {
      if (this.$refs.confirmationForm.validate()) {
        this.loading = true;
        this.$axios
          .get("https://api.axtoken.net/api/v2/my-order", {
            params: {
              name: this.selectedCoin,
              delegate_type: this.delegateType,
              number_of_order: this.numberOfOrder,
              total: this.total,
              order_per_unit: this.valuePerUnit,
              trade_type: "market",
              market_type: this.marketType,
              transaction_password: this.password,
            },
          })
          .then((response) => {
            this.loading = false;
            if (response.data.message == "success") {
              this.$swal({
                title: "Success",
                text: "Order created successfully",
                icon: "success",
                timer: 3000,
                buttons: false,
              });
              this.$store.dispatch("setWallet");
              this.confirmDialog = false;
            } else if (response.data.message === "wrong_transaction_password") {
              this.$swal({
                title: "Error",
                text: "Wrong transaction password",
                icon: "error",
                timer: 3000,
                buttons: false,
              });
              this.errorMessage.password = "Wrong transaction password";
            } else {
              this.$swal({
                title: "Error",
                text: "Something went wrong",
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$swal({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 3000,
              buttons: false,
            });
          });
      }
    },
    ratingText(index) {
      return index == 0
        ? "25%"
        : index == 1
        ? "50%"
        : index == 2
        ? "75%"
        : "100%";
    },
    setAmountPercentage() {
      this.amount = this.balanceToDisplay * this.amountPercentage * 0.25;
    },
    async getTradesChart() {
      try {
        const response = await this.$axios.get(
          `https://api.axtoken.net/api/v2/chart/chart-series?symbol=${this.selectedCoin}&timeframe=${this.timeFrame}`
        );
        //Save marketCoins to vuex
        // this.$store.commit("setMarketCoins", response.data.coinpair);
        this.dataRaw = response.data;
        //Get current price
        this.coinDetails.amount = this.dataRaw.latest_time.close;
        this.latestDetails = this.dataRaw.latest_time;
        //Get percent change
        // this.coinDetails.percent = this.dataRaw.latest_time.percent;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    async fetchOrders() {
      try {
        this.$axios
          .get(`https://api.axtoken.net/api/v2/recent-trades`, {
            params: {
              name: this.selectedCoin + "USDT",
            },
          })
          .then((response) => {
            //Save Orders to vuex
            this.$store.commit("setOrders", response.data);
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  },

  mounted() {
    this.selectedCoin = this.$route.params.coin;
    if (this.$store.getters.isAuthenticated) {
      this.fetchOrders();
      this.$store.dispatch("setWallets");
      this.fetchMinimum(this.selectedCoin);
    }
    this.getTradesChart();
    //Add interval
    this.interval = setInterval(() => {
      this.getTradesChart();
    }, 5000);
  },
  beforeDestroy() {},
  beforeRouteEnter(to, from, next) {
    const isAuthenticated = store.getters.isAuthenticated;
    if (isAuthenticated) {
      next();
    } else {
      next("/signin");
    }
  },
};
</script>
<style>
.toolbar-buttons {
  background-color: transparent !important;
}
.trade-container {
  width: 100vw !important;
  border-radius: 20px !important;
  background-color: #1e1e1e !important;
}
.full-width-toggle {
  display: flex;
  width: 100%;
}

.full-width-toggle .v-btn {
  flex-grow: 1;
  flex-basis: 0;
}
.v-text-field.v-text-field--solo .v-input__control {
  height: 28px !important;
}
.v-text-field.v-text-field--solo .v-input__control .v-input__slot {
  height: 28px !important;
}
.price-list {
  font-size: 11px;
  color: #b3b3b3;
}
.list-title {
  font-size: 13px;
}
.sub-caption {
  font-size: 10px;
  color: #b3b3b3;
}

.rating-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-with-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-label {
  margin-top: 0px;
  font-size: 9px;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
.volume-caption {
  font-size: 10px !important;
  margin-bottom: 0px !important;
  color: #b3b3b3;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
