<template>
  <v-app>
    <main-toolbar />
    <main-view />
    <main-footer />
  </v-app>
</template>
<script>
export default {
  data: () => ({
    components: ["Home", "Trades", "Wallet", "Markets", "Futures"],
  }),
  computed: {
    checkRoutes() {
      return this.components.includes(this.$route.name);
    },
    inAuthentication() {
      return this.$route.name === "SignIn" || this.$route.name === "SignUp";
    },
  },
};
</script>
