<template>
  <v-row justify="center">
    <v-col cols="5">
      <v-toolbar class="transparent elevation-0">
        <v-btn icon color="secondary" @click="goBack">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Withdraw USDT</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar>
      <v-container>
        <v-form ref="withdrawForm" lazy-loading>
          <v-row justify="center">
            <v-col cols="10">
              <label>Wallet Address</label>
              <v-textarea
                v-model="address"
                dense
                rows="2"
                append-icon="mdi-qrcode"
                clickable
                @click:append="openQRScanning()"
                :rules="rules.required"
              />
            </v-col>
            <v-col cols="10">
              <label>Network</label>
              <v-select
                v-model="selectedType"
                :items="walletTypes"
                item-text="name"
                item-value="name"
                dense
                solo
              />
            </v-col>
            <v-col cols="10">
              <label>Minimum Withdrawable</label>
              <v-text-field
                v-model="
                  walletTypes.find((item) => item.name === selectedType).minimum
                "
                dense
                disabled
                solo
              />
            </v-col>
            <v-col cols="10">
              <label>Transaction Fee</label>
              <v-text-field
                v-model="
                  walletTypes.find((item) => item.name === selectedType).gasFee
                "
                dense
                disabled
                solo
              />
            </v-col>
            <v-col cols="10">
              <label>Amount(USDT)</label>
              <v-text-field v-model="amount" dense :rules="amountRules" />
            </v-col>
            <v-col cols="10">
              <label>Amount to receive</label>
              <v-text-field v-model="receivable" dense solo readonly />
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary"
                rounded
                large
                block
                @click="proceed(), (loader = 'loading')"
                :loading="loading"
                :disabled="loading"
              >
                Proceed
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-dialog v-model="confirmDialog" persistent>
        <v-card width="400px">
          <v-card-title>
            <v-toolbar class="elevation-0 transparent">
              <v-spacer></v-spacer>
              <v-toolbar-title>Confirmation</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-form ref="confirmationForm" lazy-loading>
              <v-row>
                <v-col cols="12">
                  <label>Enter the OTP code</label>
                  <v-text-field
                    v-model="code"
                    dense
                    :rules="rules.required"
                    :error-messages="errorMessage.code"
                  />
                </v-col>
                <v-col cols="12">
                  <label>Transaction Password</label>
                  <v-text-field
                    v-model="password"
                    :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="visible = !visible"
                    :rules="rules.required"
                    :type="visible ? 'text' : 'password'"
                    dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" large text @click="confirmDialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="px-4"
              large
              rounded
              @click="withdraw(), (loader = 'loading')"
              :loading="loading"
              :disabled="loading"
            >
              Confirm
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    visible: false,
    walletTypes: [
      { name: "TRC20", minimum: 10, gasFee: 1 },
      { name: "ERC20", minimum: 30, gasFee: 20 },
      { name: "OMNI", minimum: 10, gasFee: 1 },
    ],
    selectedType: "TRC20",
    address: null,
    amount: null,
    code: null,
    password: null,
    errorMessage: {
      code: null,
    },
    rules: {
      //Form Field Rules
      required: [(v) => !!v || "Field is required"],
      address: [(v) => !!v || "Address is required"],
      amount: [
        (v) => !!v || "Amount is required",
        (v) => v > 0 || "Amount must be greater than 0",
        //Amount should be greater than minimum withdrawable and less than balance
        (v) =>
          v >=
            this.walletTypes.find((item) => item.name === this.selectedType)
              .minimum || "Amount must be greater than minimum withdrawable",
        (v) =>
          v <= this.balance ||
          "Amount must be less than or equal to your balance",
      ],
    },
    confirmDialog: false,
  }),
  computed: {
    balance() {
      let wallet = this.$store.getters.wallet;
      return wallet ? wallet.usdtBalance.wallet_balance : 0;
    },
    minimumRequiredAmount() {
      const selectedWalletType = this.walletTypes.find(
        (item) => item.name === this.selectedType
      );
      return selectedWalletType ? selectedWalletType.minimum : 0;
    },
    amountRules() {
      return [
        (v) => !!v || "Amount is required",
        (v) =>
          v >= this.minimumRequiredAmount ||
          `Amount must be greater than minimum withdrawable (${this.minimumRequiredAmount})`,
        (v) =>
          v <= this.balance ||
          `Amount must be less than or equal to your balance (${this.balance})`,
      ];
    },
    receivable() {
      return this.amount
        ? this.amount -
            this.walletTypes.find((item) => item.name === this.selectedType)
              .gasFee
        : 0;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    proceed() {
      if (this.$refs.withdrawForm.validate()) {
        this.loading = true;
        this.$axios.post("https://api.axtoken.net/api/v1/otp").then(() => {
          this.loading = false;
          this.confirmDialog = true;
        });
      }
    },
    withdraw() {
      if (this.$refs.confirmationForm.validate()) {
        this.loading = true;
        this.$axios
          .post("https://api.axtoken.net/api/v1/withdrawals", {
            bchain_name: this.selectedType,
            withdraw_address: this.address,
            withdraw_amount: this.amount,
            gasfee: this.walletTypes.find(
              (item) => item.name === this.selectedType
            ).gasFee,
            verification: this.code,
            transaction_password: this.password,
          })
          .then((response) => {
            console.log(response);
            this.loading = false;
            if (response.data.message === "wrong_code") {
              this.$swal({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Wrong OTP code",
                showConfirmButton: false,
                timer: 3000, // milliseconds
              });
              this.errorMessage.code = "Invalid Code";
            } else if (response.data.message === "wrong_password") {
              this.$swal({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Wrong transaction password",
                showConfirmButton: false,
                timer: 3000, // milliseconds
              });
            } else {
              this.loading = false;
              this.$swal({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Withdrawal request sent",
                showConfirmButton: false,
                timer: 3000, // milliseconds
              });
              this.$store.dispatch("setWallet");
              this.$router.push("/home");
            }
          });
      }
    },
  },
  mounted() {},
};
</script>
<style>
/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
