<template>
    <div>
        <v-container fluid>
            <v-chart ref="echart" class="chart" :option="option" />
        </v-container>
        <v-card v-show="loading" height="300px" class="elevation-0 transparent">
            <v-card-text class="text-center">
                <v-progress-circular indeterminate size="64" color="primary" />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { CandlestickChart } from "echarts/charts";
import { BarChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    SingleAxisComponent,
    ToolboxComponent,
    DataZoomComponent,
    VisualMapComponent,
    MarkLineComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
    CanvasRenderer,
    CandlestickChart,
    BarChart,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    SingleAxisComponent,
    ToolboxComponent,
    DataZoomComponent,
    VisualMapComponent,
    MarkLineComponent,
]);
let data = {
    dates: [],
    series: [],
    latest_time: {
        close: 0,
    },
};
export default {
    components: {
        VChart,
    },
    props: {
        dataRaw: {
            type: Object,
            default: () => [],
        },
    },
    provide: {
        [THEME_KEY]: "dark",
    },
    data() {
        return {
            option: {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        animation: false,
                        type: "cross",
                        lineStyle: {
                            color: "#376df4",
                            width: 2,
                            opacity: 1,
                        },
                    },
                },
                backgroundColor: "#121212",
                xAxis: {
                    type: "category",
                    data: data.dates,
                    axisLine: { lineStyle: { color: "#8392A5" } },
                },
                yAxis: {
                    scale: true,
                    axisLine: { lineStyle: { color: "#10bd7d" } },
                    splitLine: { show: false },
                    position: "right",
                },
                grid: {
                    left: 0,
                    right: 40,
                    bottom: 80, // adjust the bottom value to accommodate the dataZoom
                },
                dataZoom: [
                    {
                        textStyle: {
                            color: "#8392A5",
                        },
                        handleIcon:
                            "path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
                        dataBackground: {
                            areaStyle: {
                                color: "#8392A5",
                            },
                            lineStyle: {
                                opacity: 0.8,
                                color: "#8392A5",
                            },
                        },
                        brushSelect: true,
                        startValue: data.series.length - 20,
                    },
                    {
                        type: "inside",
                        startValue: data.series.length - 20,
                    },
                ],
                series: [
                    {
                        type: "candlestick",
                        name: "Day",
                        data: data.series,
                        itemStyle: {
                            color: "#10bd7d",
                            color0: "#f64649",
                            borderColor: "#10bd7d",
                            borderColor0: "#f64649",
                        },
                        markLine: {
                            symbol: "none",
                            label: {
                                show: true,
                                formatter: "{c}",
                                backgroundColor: "#10bd7d",
                                borderRadius: 3,
                                padding: 6,
                                color: "#ffffff",
                                position: "end",
                                offset: [-22, 0],
                            },
                            data: [
                                {
                                    yAxis: data.latest_time.close,
                                    lineStyle: {
                                        color: "#10bd7d",
                                        width: 1,
                                        type: "dashed",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            loading: true,
        };
    },
    watch: {
        dataRaw: {
            handler: function (newVal) {
                this.$refs.echart.setOption({
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            animation: false,
                            type: "cross",
                            lineStyle: {
                                color: "#376df4",
                                width: 2,
                                opacity: 1,
                            },
                        },
                    },
                    backgroundColor: "#121212",
                    xAxis: {
                        type: "category",
                        data: newVal.dates,
                        axisLine: { lineStyle: { color: "#8392A5" } },
                    },
                    yAxis: {
                        scale: true,
                        axisLine: { lineStyle: { color: "#10bd7d" } },
                        splitLine: { show: false },
                        position: "right",
                    },
                    grid: {
                        left: 0,
                        right: 40,
                        bottom: 80, // adjust the bottom value to accommodate the dataZoom
                    },
                    dataZoom: [
                        {
                            textStyle: {
                                color: "#8392A5",
                            },
                            handleIcon:
                                "path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
                            dataBackground: {
                                areaStyle: {
                                    color: "#8392A5",
                                },
                                lineStyle: {
                                    opacity: 0.8,
                                    color: "#8392A5",
                                },
                            },
                            brushSelect: true,
                            startValue: newVal.series.length - 20,
                        },
                        {
                            type: "inside",
                            startValue: newVal.series.length - 20,
                        },
                    ],
                    series: [
                        {
                            type: "candlestick",
                            name: "Day",
                            data: newVal.series,
                            itemStyle: {
                                color: "#10bd7d",
                                color0: "#f64649",
                                borderColor: "#10bd7d",
                                borderColor0: "#f64649",
                            },
                            markLine: {
                                symbol: "none",
                                label: {
                                    show: true,
                                    formatter: "{c}",
                                    backgroundColor: "#10bd7d",
                                    borderRadius: 3,
                                    padding: 6,
                                    color: "#ffffff",
                                    position: "end",
                                    offset: [-22, 0],
                                },
                                data: [
                                    {
                                        yAxis: newVal.latest_time.close,
                                        lineStyle: {
                                            color: "#10bd7d",
                                            width: 1,
                                            type: "dashed",
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                });
                this.loading = false;
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.chart {
    height: 400px;
}
</style>
