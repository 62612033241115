<template>
  <v-row justify="center">
    <v-col cols="5">
      <!-- <v-toolbar>
        <v-btn icon color="secondary" to="/account">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title class="">Rewards Center</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn to="settings" icon>
          <icon icon="heroicons:cog-solid" width="24" height="24" />
        </v-btn>
      </v-toolbar> -->
      <v-container v-if="!loading">
        <div v-if="tasks.length > 0">
          <v-card
            class="mt-4"
            v-for="(task, index) in tasks"
            :key="index"
            :disabled="
              task.reward_status == 'CLAIMED' || task.task_status == 'EXPIRED'
                ? true
                : false
            "
          >
            <v-card-text>
              <v-row align="start" class="ma-0" @click="coinClicked()">
                <v-col cols="12">
                  <v-row>
                    <v-col class="pt-0 pb-1">
                      <h5 class="font-weight-bold white--text">
                        {{ task.task_center.description }}
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="space-between">
                    <v-col cols="7" class="ma-0 pa-0">
                      <v-chip class="ma-2" color="primary" label>
                        <v-icon left> mdi-ticket-confirmation </v-icon>
                        {{ task.task_center.reward_amount }}
                      </v-chip>
                      <v-chip
                        :color="
                          task.task_center.status == 'EXPIRED'
                            ? 'greyPrimary'
                            : 'secondary'
                        "
                        label
                        :disabled="
                          task.task_center.status == 'EXPIRED' ? true : false
                        "
                      >
                        {{ task.task_center.status }}
                      </v-chip>
                    </v-col>
                    <v-col
                      v-if="task.task_status != 'EXPIRED'"
                      cols="12"
                      class="px-0 text-right"
                    >
                      <v-btn
                        block
                        :disabled="
                          task.reward_status == 'CLAIMED' ||
                          task.task_status == 'ONGOING'
                            ? true
                            : false
                        "
                        color="greenPrimary"
                        @click="claimTask(task)"
                        >Claim</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div v-else>
          <v-card class="mt-4 transparent elevation-0">
            <v-card-text class="text-center">
              <v-row align="start" class="ma-0">
                <v-col cols="12">
                  <v-row>
                    <v-col class="pt-0 pb-1">
                      <h5 class="font-weight-bold">No rewards available</h5>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
      <v-container v-show="loading">
        <v-skeleton-loader
          v-show="loading"
          v-for="index in 10"
          :key="index"
          type="list-item-three-line"
          class="mt-3"
        ></v-skeleton-loader>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    tasks: [
      {
        amount: 0,
        task_status: "pending",
        task_center: {
          id: 1,
          description: "Watch 1 video",
          reward_amount: 1,
          amount: 1,
        },
      },
    ],
  }),
  methods: {
    fetchRewards() {
      this.loading = true;
      this.$axios
        .get("https://api.axtoken.net/api/v1/task/reward")
        .then((response) => {
          this.tasks = response.data.client_task;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    progress(task) {
      return (task.amount / task.task_center.amount) * 100;
    },
    claimTask(task) {
      this.loading = true;
      this.$axios
        .get("https://api.axtoken.net/api/v1/claim-task", {
          params: { task_center_id: task.id },
        })
        .then(() => {
          this.fetchRewards();
          this.$store.dispatch("setWallet");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetchRewards();
  },
};
</script>
