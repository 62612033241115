<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "App",
  data: () => ({}),
  computed: {},
  mounted() {
    this.$store.dispatch("setMarketCoins");
    setInterval(() => {
      this.$store.dispatch("setMarketCoins");
    }, 20000);
  },
};
</script>
<style type="scss">
@import url("https://fonts.googleapis.com/css? family=Oxygen:300,400,700&display=swap");

$body-font-family: "Oxygen";
$title-font: "Comfortaa";
</style>
