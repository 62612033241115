<template>
  <div>
    <v-tabs
      class="mt-3"
      grow
      :show-arrows="false"
      color="secondary"
      background-color="#00000000"
    >
      <v-tab v-for="(coins, key) in filteredCoins" :key="key">{{
        key.toUpperCase()
      }}</v-tab>
      <v-tab-item v-for="(coins, key) in filteredCoins" :key="key">
        <v-data-table
          :headers="headers"
          :items="coins"
          :items-per-page="20"
          hide-default-footer
          class="transparent"
          @click:row="rowClicked($event)"
        >
          <template v-slot:item.name="{ item }">
            <v-avatar size="25" class="mr-2">
              <img :src="item.image" :alt="item.name" />
            </v-avatar>
            {{ item.name }}
          </template>
          <template v-slot:item.amount="{ item }">
            <h6 :class="priceColor(item.percent)">{{ item.amount }}</h6>
          </template>
          <template v-slot:item.percent="{ item }">
            <h6 :class="percentColor(item.percent)">{{ item.percent }}</h6>
          </template>
          <template v-slot:item.value="{ item }">
            <h6 :class="percentColor(item.percent)">{{ item.amount }}</h6>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
    <v-row justify="center">
      <v-col cols="12" lg="4" v-for="(coins, key) in filteredCoins" :key="key">
        <v-container
          v-if="
            Object.values(filteredCoins).some(
              (coinArray) => coinArray.length > 0
            )
          "
          class="mt-4 market-containers"
        >
          <h5 class="secondary--text text-center">{{ key }} Coins</h5>
          <main-markets-market-coin
            v-for="coin in coins"
            :key="coin.id"
            :name="coin.name"
            :image="coin.image"
            :percent="coin.percent"
            :amount="coin.amount"
          />
        </v-container>
        <v-row v-else justify="center">
          <v-col cols="12" class="text-center">
            <h3 class="mt-5">No coins found</h3>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        {
          value: "name",
          text: "Name",
          align: "start",
          sortable: true,
        },
        {
          value: "amount",
          text: "Price",
          sortable: true,
          align: "start",
        },
        {
          value: "percent",
          text: "Change",
          sortable: true,
          align: "center",
        },
        {
          value: "amount",
          text: "24H Volume",
          sortable: true,
          align: "center",
        },
        {
          value: "value",
          text: "Market Cap",
          sortable: true,
          align: "end",
        },
      ],
    };
  },
  methods: {
    priceColor(percent) {
      return percent > 0 ? "greenPrimary--text text--lighten-1" : "";
    },
    percentColor(percent) {
      return percent > 0
        ? "greenPrimary--text text--lighten-1"
        : "redPrimary--text text--lighten-1";
    },
    rowClicked(value) {
      this.$store.state.selectedCoin = value.name;
      this.$router.push(`/trades/spot/${value.name}`);
    },
  },
  mounted() {},
  computed: {
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    filteredCoins() {
      return {
        MAIN: this.marketCoins.filter((coin) => coin.type === "MAIN"),
        ETH: this.marketCoins.filter((coin) => coin.type === "ETH"),
        ALT: this.marketCoins.filter((coin) => coin.type === "ALT"),
      };
    },
  },
};
</script>
<style scoped>
.v-tab {
  min-width: 60px !important;
}
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
.market-containers {
  border-radius: 20px !important;
  background-color: #1e1e1e !important;
}
</style>
