<template>
    <v-row align="center" class="ma-0" @click="openDetails()">
        <v-col cols="2" style="margin: 0 0 0 0 !important">
            <div class="d-flex justify-content-start align-items-center">
                <v-avatar size="25">
                    <img :src="image" :alt="name" />
                </v-avatar>
                <strong class="ml-3">{{ name }}</strong>
                <span class="caption grey--text darken-2"></span>
            </div>
        </v-col>
        <v-col
            cols="10"
            class="text-center"
            style="line-height: normal; margin: 0 0 0 0 !important"
        >
            <div class="d-flex justify-content-end align-items-center">
                <div class="mr-4">
                    <p
                        class="font-weight-bold greenPrimary--text text--lighten-1 mb-0"
                    >
                        {{ percentWithSign }}
                    </p>
                </div>
                <div>
                    <p
                        class="font-weight-bold greenPrimary--text text--lighten-1 mb-0"
                    >
                        {{ percentWithSign }}
                    </p>
                </div>
            </div>
        </v-col>
        <v-dialog
            v-if="this.apr != 'Not available'"
            transition="dialog-bottom-transition"
            v-model="detailsDialog"
            fullscreen
        >
            <v-card>
                <v-toolbar>
                    <v-btn
                        icon
                        color="secondary"
                        @click="detailsDialog = false"
                    >
                        <icon
                            icon="material-symbols:arrow-back"
                            width="24"
                            height="24"
                        ></icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Create a plan</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon> </v-btn>
                </v-toolbar>
                <v-card-text class="mt-8">
                    <v-form ref="earnForm" lazy-loading>
                        <v-row>
                            <div
                                class="d-flex justify-content-start align-items-center mb-2"
                            >
                                <v-avatar size="25">
                                    <img :src="image" :alt="name" />
                                </v-avatar>
                                <h5 class="ml-3 mb-0 white--text">
                                    {{ name }}
                                </h5>
                            </div>
                            <div class="d-flex justify-content-between">
                                <label class="secondary--text">Amount</label>
                                <label class="success--text font-weight-bold"
                                    >Available: 12312
                                </label>
                            </div>
                            <v-col cols="12" class="pa-0">
                                <v-text-field
                                    dense
                                    filled
                                    required
                                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                                    v-model="amount"
                                    :rules="rules()"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p class="sub-title1 mb-0 white--text">
                                    <strong class="white--text"
                                        >Use Simple Earn Felixble
                                        Balance?</strong
                                    >
                                </p>
                                <p class="sub-title1 mb-0 grey--text">
                                    Assets are redeemed from Simple Earn
                                    Flexible Balance and used towards
                                    Auto-Invest when the spot balance is
                                    insufficient.
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <label class="mb-3 font-weight-bold"
                                >Recuring Cycle</label
                            >
                            <v-item-group mandatory v-model="selectedCycle">
                                <v-row>
                                    <v-col
                                        v-for="cycle in cycleOptions"
                                        :key="cycle"
                                        cols="3"
                                        class="pa-1"
                                    >
                                        <v-item
                                            :value="cycle"
                                            v-slot="{ active, toggle }"
                                        >
                                            <v-card
                                                :color="active ? 'primary' : ''"
                                                class="d-flex align-center"
                                                dark
                                                height="30"
                                                @click="toggle"
                                            >
                                                <v-scroll-y-transition>
                                                    <div
                                                        class="flex-grow-1 text-center"
                                                    >
                                                        {{ cycle }}
                                                    </div>
                                                </v-scroll-y-transition>
                                            </v-card>
                                        </v-item>
                                    </v-col>
                                </v-row>
                            </v-item-group>
                        </v-row>
                        <v-row v-if="showDayOptions">
                            <label class="mt-4 mb-3 font-weight-bold"
                                >Repeats On</label
                            >
                            <v-item-group mandatory v-model="day">
                                <v-row>
                                    <v-col
                                        v-for="day in dayOptions"
                                        :key="day"
                                        cols="3"
                                        class="pa-1"
                                    >
                                        <v-item
                                            :value="day"
                                            v-slot="{ active, toggle }"
                                        >
                                            <v-card
                                                :color="active ? 'primary' : ''"
                                                class="d-flex align-center"
                                                dark
                                                height="30"
                                                @click="toggle"
                                            >
                                                <v-scroll-y-transition>
                                                    <div
                                                        class="flex-grow-1 text-center"
                                                    >
                                                        {{ day }}
                                                    </div>
                                                </v-scroll-y-transition>
                                            </v-card>
                                        </v-item>
                                    </v-col>
                                </v-row>
                            </v-item-group>
                        </v-row>
                        <v-row v-if="selectedCycle == 'Monthly'">
                            <label class="mt-4 mb-3 font-weight-bold"
                                >Repeats on date of the month</label
                            >
                            <v-col>
                                <v-select
                                    solo
                                    :items="dateOptions"
                                    v-model="date"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row class="mt-4">
                            <v-col>
                                <label class="mb-3 font-weight-bold"
                                    >Repeat on local time</label
                                >
                                <div class="mb-4 d-flex" style="flex: 12">
                                    <div class="pt-0 mt-0" style="flex: 12">
                                        <v-dialog
                                            ref="timeDialog"
                                            v-model="timeModal"
                                            :return-value.sync="time"
                                            persistent
                                            width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="time"
                                                    label="Time"
                                                    readonly
                                                    solo
                                                    height="28"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="timeModal"
                                                v-model="time"
                                                full-width
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="timeModal = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="
                                                        $refs.timeDialog.save(
                                                            time
                                                        )
                                                    "
                                                >
                                                    OK
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <label class="h6">Summary</label>
                                <v-card>
                                    <v-card-text>
                                        <v-timeline align-top dense>
                                            <v-timeline-item
                                                color="secondary"
                                                small
                                            >
                                                <v-row class="pt-1">
                                                    <v-col>
                                                        <div
                                                            class="text-caption"
                                                        >
                                                            Create Time
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="5">
                                                        <strong>{{
                                                            createTime
                                                        }}</strong>
                                                    </v-col>
                                                </v-row>
                                            </v-timeline-item>
                                            <v-timeline-item
                                                color="greenPrimary"
                                                hide-dot
                                            >
                                                <v-row class="pt-1">
                                                    <v-col>
                                                        <div
                                                            class="text-caption"
                                                        >
                                                            Amount Per Period
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="5">
                                                        <strong>--</strong>
                                                    </v-col>
                                                </v-row>
                                            </v-timeline-item>
                                            <v-timeline-item
                                                color="greenPrimary"
                                                small
                                            >
                                                <v-row class="pt-1">
                                                    <v-col>
                                                        <div
                                                            class="text-caption"
                                                        >
                                                            First Auto-Invest
                                                            Date
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="5">
                                                        <strong>{{
                                                            firstAutoInvestDate
                                                        }}</strong>
                                                    </v-col>
                                                </v-row>
                                            </v-timeline-item>
                                        </v-timeline>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="center" no-gutter>
                        <v-col class="pl-1 mb-1"
                            ><v-btn
                                block
                                large
                                color="secondary"
                                @click="invest('STAKE_LOCK')"
                                >Confirm
                            </v-btn></v-col
                        >
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    props: {
        id: String,
        name: String,
        image: String,
        apr: String,
        earn: Array,
    },
    data() {
        return {
            detailsDialog: false,
            timeModal: false,
            time: null,
            selectedCycle: "Daily",
            day: "MON",
            date: "1",
            dayOptions: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
            dateOptions: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
            ],
            cycleOptions: [
                "Daily",
                "Weekly",
                "Bi-Weekly",
                "Monthly",
                "1 Hour",
                "4 Hours",
                "8 Hours",
                "12 Hours",
            ],
            amount: 0,
            balance: 0,
            totalQuota: 0,
        };
    },
    computed: {
        percentColor() {
            //Return the color based on the percent value if positive or negative
            return this.apr != "Not available"
                ? "percent-card greenPrimary--text d-flex align-center justify-center transparent elevation-0"
                : "percent-card redPrimary--text d-flex align-center justify-center transparent elevation-0";
        },
        percentWithSign() {
            //Return the percent value with a sign
            return this.apr != "Not available"
                ? "APR " + this.apr + "%"
                : "Not Available";
        },
        minimum() {
            return parseFloat(this.earn[0].minimum_amount);
        },
        maximum() {
            return parseFloat(this.earn[0].maximum_amount);
        },
        createTime() {
            return new Date().toLocaleString();
        },
        firstAutoInvestDate() {
            const currentDate = new Date();
            let first = new Date(); // Adding 1 day in milliseconds
            switch (this.selectedCycle) {
                case "Daily":
                    first = new Date(
                        currentDate.getTime() + 24 * 60 * 60 * 1000
                    ); //
                    first.setHours(0, 0, 0, 0);
                    break;
                case "Weekly":
                    first = new Date(
                        currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
                    ); //
                    first.setHours(0, 0, 0, 0);
                    break;
                case "Bi-Weekly":
                    first = new Date(
                        currentDate.getTime() + 14 * 24 * 60 * 60 * 1000
                    ); //
                    first.setHours(0, 0, 0, 0);
                    break;
                case "Monthly":
                    first = new Date(
                        currentDate.getTime() + 30 * 24 * 60 * 60 * 1000
                    ); //
                    first.setHours(0, 0, 0, 0);
                    break;
                case "1 Hour":
                    first = new Date(
                        currentDate.getTime() + 1 * 60 * 60 * 1000
                    ); //
                    break;
                case "4 Hours":
                    first = new Date(
                        currentDate.getTime() + 4 * 60 * 60 * 1000
                    ); //
                    break;
                case "8 Hours":
                    first = new Date(
                        currentDate.getTime() + 8 * 60 * 60 * 1000
                    ); //
                    break;
                case "12 Hours":
                    first = new Date(
                        currentDate.getTime() + 12 * 60 * 60 * 1000
                    ); //
                    break;
            } // Adding 1 day in milliseconds
            return first.toLocaleString();
        },
        showDayOptions() {
            return (
                this.selectedCycle == "Weekly" ||
                this.selectedCycle == "Bi-Weekly"
            );
        },
    },
    methods: {
        rules() {
            return [
                (v) => !!v || "Amount is required",
                (v) =>
                    v >= this.minimum ||
                    "Amount must be greater than or equal minimum",
                (v) => v <= this.maximum || "Amount must be less than maximum",
                (v) =>
                    v <= this.balance ||
                    "Amount must be less than or equal balance",
            ];
        },
        maxAmount() {
            this.amount = this.balance;
        },
        openDetails() {
            this.$axios
                .get("https://api.axtoken.net/api/v1/wallet-balance", {
                    params: {
                        name: this.name,
                    },
                })
                .then((response) => {
                    this.balance = response.data.wallet.wallet_balance;
                    if (this.apr != "Not available") this.detailsDialog = true;
                });
        },
        invest(type) {
            if (this.$refs.earnForm.validate()) {
                this.$axios
                    .post("https://api.axtoken.net/api/v2/earn/store", {
                        earn_id: this.earn[0].id,
                        cycles: this.selectedCycle,
                        amount: this.amount,
                        status: type,
                    })
                    .then((response) => {
                        if (response.data.message == "success") {
                            this.$swal({
                                title: "Success",
                                text: "Investment successful",
                                icon: "success",
                                timer: 3000,
                                buttons: false,
                            });
                            this.$store.dispatch("setWallet");
                            this.$router.push("/home");
                        } else {
                            this.$swal({
                                title: "Error",
                                text: "Something went wrong",
                                icon: "error",
                                timer: 3000,
                                buttons: false,
                            });
                        }
                    });
            }
        },
    },
};
</script>
<style>
.percent-card {
    font-weight: 550;
}
.v-timeline::before {
    top: 55px;
    height: calc(100% - 110px);
}
</style>
