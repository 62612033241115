<template>
  <v-card class="balance-card">
    <v-card-text>
      <v-row justify="space-between">
        <v-col>
          <span class="caption">Total Balance(BTC)</span>
          <h2 class="font-weight-bold mb-0 white--text">
            {{ balance | commaFormat }}
          </h2>
          <span class="sub-caption">={{ balance | commaFormat }} USDT</span>
        </v-col>
        <v-col class="text-end">
          <v-row justify="center" no-gutter>
            <v-col cols="3" class="pr-1 mb-1"
              ><v-btn
                to="/wallet/deposit"
                block
                large
                color="secondary"
                class="black--text"
                >Deposit
              </v-btn></v-col
            >
            <v-col cols="3" class="pl-1 pr-1 mb-1"
              ><v-btn to="/wallet/withdraw" block large color="greyPrimary"
                >Withdraw
              </v-btn></v-col
            >
            <v-col cols="3" class="pl-1 pr-1 mb-1"
              ><v-btn to="/wallet/transfer" block large color="greyPrimary"
                >Transfer
              </v-btn></v-col
            >
            <v-col cols="3" class="pl-1 mb-1"
              ><v-btn to="/history" block large color="greyPrimary"
                >History
              </v-btn></v-col
            >
          </v-row>
          <!-- <v-btn icon small to="/history">
            <icon
              icon="ri:file-list-3-fill"
              width="18"
              height="18"
              color="gray"
            ></icon>
          </v-btn> -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    balance: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style>
.balance-card {
  margin-top: 20px !important;
  border-radius: 30px 30px 0 0 !important;
  width: 100%;
  margin: 0;
  padding: 0;
}

.sub-caption {
  font-size: 10px;
}
</style>
