<template>
  <v-row justify="center">
    <v-col cols="8">
      <v-toolbar class="transparent elevation-0">
        <v-btn icon color="secondary" @click="goBack">
          <icon
            icon="material-symbols:arrow-back"
            width="24"
            height="24"
          ></icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Auto Invest</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon> </v-btn>
      </v-toolbar>
      <main-earn-auto-invest-coin-list :search="search" />
    </v-col>
  </v-row>
</template>
<script>
import store from "@/store";
export default {
  name: "auto-invest",
  data: () => ({
    search: "",
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    const isAuthenticated = store.getters.isAuthenticated;
    if (isAuthenticated) {
      next();
    } else {
      next("/signin");
    }
  },
};
</script>
<style>
.v-tab {
  min-width: 60px !important;
}
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
.full-screen-container {
  height: 90vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
